import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Nav, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Catalogue, Logout, Settings } from "../../../assets/icons";
import "./sidebar.scss";
import { sidebarNavList } from "../../../utils/data";

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("dashboard");
    }
  }, []);
  return (
    <aside
      className="d-flex side-bar position-sticky"
      style={{ height: "calc(100vh - 72px)" }}
    >
      <Navbar
        bg="light"
        expand="sm"
        className="navbar-customization vstack py-3 border-end"
      >
        <Nav className="sidebar-one gap-6 flex-column h-full">
          {sidebarNavList.map((menu, index) => (
            <OverlayTrigger
              key={index}
              placement="right"
              delay={{ hide: 100, show: 100 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  <span className="p-3">{menu?.tooltip}</span>
                </Tooltip>
              )}
            >
              <Nav.Item className={`${menu?.showAtBottom ? "mt-auto" : ""}`}>
                <Nav.Link
                  key={index}
                  as={Link}
                  to={menu?.path}
                  className={`${
                    location.pathname.includes(menu?.path)
                      ? "hstack justify-content-center active"
                      : "hstack justify-content-center"
                  }`}
                  // className="hstack justify-content-center"
                >
                  <Image src={menu?.icon} className="w-5" />
                </Nav.Link>
              </Nav.Item>
            </OverlayTrigger>
          ))}
          <OverlayTrigger
            key={"logout_nav_tooltip"}
            placement="right"
            delay={{ hide: 100, show: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>
                <span className="p-3">{"Logout"}</span>
              </Tooltip>
            )}
          >
            <Nav.Item>
              <Nav.Link
                key={"logout_nav"}
                as={Link}
                to={"/login"}
                className={`${
                  location.pathname.includes("/login")
                    ? "hstack justify-content-center active"
                    : "hstack justify-content-center"
                }`}
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
                // className="hstack justify-content-center"
              >
                <Image src={Logout} className="w-5" />
              </Nav.Link>
            </Nav.Item>
          </OverlayTrigger>
        </Nav>
        {/* <Nav className="mt-auto sidebar-one gap-6 flex-column">
          <Nav.Item>
            <Nav.Link
              as={Link}
              to={"/membership"}
              className={`${
                location.pathname.includes("/membership")
                  ? "hstack justify-content-center active"
                  : ""
              }`}
              // className="hstack justify-content-center"
            >
              <Image src={Catalogue} style={{ width: "20px" }} />
            </Nav.Link>
            <Nav.Link
              key={"setting"}
              as={Link}
              to={"/settings"}
              className={`${
                location.pathname.includes("/settings")
                  ? "hstack justify-content-center active"
                  : ""
              }`}
              // className="hstack justify-content-center"
            >
              <Image src={Settings} style={{ width: "20px" }} />
            </Nav.Link>
          </Nav.Item>
        </Nav> */}
      </Navbar>
      <div className="border-end w-full">
        <Nav className="vstack py-5 navbar--aside-customization">
          {sidebarNavList.map((menu, index) =>
            location.pathname.includes(menu?.path) ? (
              <Nav.Item key={index}>
                <p className="font-bold p-2 text-dark">{menu.heading}</p>

                {menu.subMenus && (
                  <>
                    {menu.subMenus.map((subMenu, subIndex) => (
                      <Nav.Item key={subIndex}>
                        <Nav.Link
                          as={Link}
                          to={`${menu?.path}/${subMenu.subPath}`}
                          className={`py-2 px-2 text-semibold ${
                            location.pathname.includes(subMenu.subPath)
                              ? "active border-end border-3 border-primary border-solid"
                              : ""
                          }`}
                        >
                          {subMenu.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </>
                )}
              </Nav.Item>
            ) : null
          )}

          {/* <Nav.Item>
            <p className="font-bold p-2 text-dark">Inventory</p>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/inventory/addproduct">
              Add new product
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="active">
            <Nav.Link as={Link} to="/inventory/productlist">
              Product list
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/inventory/insight">
              Inventory insights
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>
    </aside>
  );
}

export default Sidebar;
