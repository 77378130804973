import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DateTime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { validateAddClient } from "../../utils/validation";
import { clientAdd } from "../../redux/client";
import { clearAddClient } from "../../redux/client/clientSlice";
import { getLocationFromPinCode } from "../../utils/commonFunctions";
import moment from "moment";

function AddClientModel({ smShow, setSmShow }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    email: "",
    birthday: "",
    anniversary: "",
    notes: "",
    street_address: "",
    pincode: "",
    city: "",
    state: "",
    gender: "",
    sourceOfClient: "",
    cstGstNo: "",
  });
  const [error, setError] = useState({});

  const addClient = useSelector((state) => state?.client?.addClient);

  useEffect(() => {
    if (addClient && addClient.status === true) {
      setSmShow(false);
      setFormData({
        phone: "",
        name: "",
        email: "",
        birthday: "",
        anniversary: "",
        notes: "",
        street_address: "",
        pincode: "",
        city: "",
        state: "",
        gender: "",
        sourceOfClient: "",
        cstGstNo: "",
      });
      dispatch(clearAddClient());
    }
  }, [addClient]);

  const handlePincodeData = async (pincode) => {
    try {
      let address = await getLocationFromPinCode(pincode);
      if (Object.keys(address).length === 0) {
        setError({
          ...error,
          ["pincode"]: "No data found for the provided pincode.",
        });
      }
      let newAddressData = {
        state: address?.circle || "",
        city: address?.division || "",
      };
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...newAddressData,
      }));
      return address;
    } catch (error) {
      console.error("Error fetching location data:", error);
      // Handle error gracefully, e.g., display error message to the user
      // You can update state or display a message to the user indicating the error
      setError({ ...error, ["pincode"]: "Error fetching location data" });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "pincode") {
      // Validate pincode length
      if (value.length > 6) return;
      if (value.length === 6) {
        // Make API call when pincode length is 6
        // handlePincodeData(value);
      }
    }

    // Update form data and reset error
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setError({ ...error, [name]: null });
  };
  const handleDateChange = (name, time) => {
    const formattedDate = moment(time).format("DD-MM-YYYY");

    const momentDate = moment(formattedDate, "DD-MM-YYYY", true);
    if (!momentDate.isValid()) {
      console.log("Invalid date or time");
      return;
    }
    // const formattedDate = formatDate(time._d);

    // Update state with the formatted date
    setFormData((prevBookAppointment) => ({
      ...prevBookAppointment,
      [name]: formattedDate,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fromError = validateAddClient(formData);
    if (Object.keys(fromError).length > 0) {
      setError(fromError);
    } else {
      dispatch(clientAdd(formData));
    }
  };
  return (
    <div>
      <Modal
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Add new client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-6">
              <p className="text-sm mb-2">Client name*</p>
              <Form.Control
                type="text"
                className="border-bottom-1 ps-0 border-start-0 border-top-0 border-end-0 shadow-none rounded-0"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <p className="text-danger">{error?.name}</p>
            </Form.Group>
            <Form.Group className="mb-6">
              <p className="text-sm mb-2">Client number*</p>
              <Form.Control
                type="tel"
                name="phone"
                value={formData?.phone}
                className="border-bottom-1 ps-0 border-start-0 border-top-0 border-end-0 shadow-none rounded-0"
                onChange={handleInputChange}
                maxLength={10}
              />
              <p className="text-danger">{error?.phone}</p>
            </Form.Group>
            <Form.Group className="mb-6">
              <p className="text-sm mb-2">Gender*</p>
              <div className="d-flex gap-6">
                <Form.Check id="Female" label="Female" variant="secondary">
                  <Form.Check.Input
                    name="gender"
                    type="radio"
                    className="secondary-radio"
                    value="Female"
                    onChange={handleInputChange}
                  />
                  <Form.Check.Label className="ms-1">Female</Form.Check.Label>
                </Form.Check>
                <Form.Check id="Male" label="Male" variant="secondary">
                  <Form.Check.Input
                    name="gender"
                    type="radio"
                    className="secondary-radio"
                    value="Male"
                    onChange={handleInputChange}
                  />
                  <Form.Check.Label className="ms-1">Male</Form.Check.Label>
                </Form.Check>
                <Form.Check id="Other" label="Other" variant="secondary">
                  <Form.Check.Input
                    onChange={handleInputChange}
                    name="gender"
                    type="radio"
                    className="secondary-radio"
                    value="Other"
                  />
                  <Form.Check.Label className="ms-1">Other</Form.Check.Label>
                </Form.Check>
              </div>
              <p className="text-danger">{error?.gender}</p>
            </Form.Group>
            <Form.Group className="mb-6">
              <p className="text-sm mb-2">Source of client*</p>
              <div className="d-flex gap-6">
                <Form.Check id="WalkIn" label="Walk-in" variant="secondary">
                  <Form.Check.Input
                    onChange={handleInputChange}
                    name="sourceOfClient"
                    type="radio"
                    className="secondary-radio"
                    value="Walk-In"
                  />
                  <Form.Check.Label className="ms-1">Walk-in</Form.Check.Label>
                </Form.Check>
                <Form.Check id="Online" label="Online" variant="secondary">
                  <Form.Check.Input
                    onChange={handleInputChange}
                    name="sourceOfClient"
                    type="radio"
                    className="secondary-radio"
                    value="Online"
                  />
                  <Form.Check.Label className="ms-1">Online</Form.Check.Label>
                </Form.Check>
              </div>
              <p className="text-danger">{error?.sourceOfClient}</p>
            </Form.Group>
            <Form.Group className="mb-6">
              <p className="text-sm mb-2">Email (optional)</p>
              <Form.Control
                type="email"
                name="email"
                className="border-bottom-1 ps-0 border-start-0 border-top-0 border-end-0 shadow-none rounded-0"
                value={formData.email}
                onChange={handleInputChange}
              />
              <p className="text-danger">{error?.email}</p>
            </Form.Group>
            <Row className="mb-6">
              <Col md={6}>
                <Form.Group>
                  <p className="text-sm mb-2">Birthday</p>
                  <DateTime
                    value={formData.birthday}
                    input={true} // Enable the input field
                    dateFormat="DD-MM-yyyy" // Date format
                    timeFormat={false} // Disable the time picker
                    onChange={(time) => handleDateChange("birthday", time)}
                    inputProps={{ placeholder: "DD-MM-YYYY" }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <p className="text-sm mb-2">Aniversary</p>
                  <DateTime
                    value={formData.anniversary}
                    input={true} // Enable the input field
                    dateFormat="DD-MM-yyyy" // Date format
                    timeFormat={false} // Disable the time picker
                    onChange={(time) => handleDateChange("anniversary", time)}
                    inputProps={{ placeholder: "DD-MM-YYYY" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group
              className="mb-6"
              controlId="exampleForm.ControlTextarea1"
            >
              <p className="text-sm mb-2">Notes</p>

              <Form.Control
                as="textarea"
                rows={3}
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
              />
            </Form.Group>
            <p className="text-sm mb-2">Address</p>
            <Row className="mb-6 gy-3 gx-3">
              <Col md={6}>
                <Form.Control
                  type="address"
                  placeholder="Street Address"
                  name="street_address"
                  value={formData.street_address}
                  onChange={handleInputChange}
                />
                <p className="text-danger">{error?.street_address}</p>
              </Col>
              <Col md={6}>
                <Form.Control
                  type="number"
                  placeholder="Pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  maxLength={6}
                />
                <p className="text-danger">{error?.pincode}</p>
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  readOnly
                  value={formData.city}
                  onChange={handleInputChange}
                />
                <p className="text-danger">{error?.city}</p>
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="state"
                  placeholder="State"
                  readOnly
                  value={formData.state}
                  onChange={handleInputChange}
                />
                <p className="text-danger">{error?.state}</p>
              </Col>
              <Col md={12}>
                <Form.Group>
                <p className="text-sm my-2">Customer GST no.</p>
                  <Form.Control
                    type="text"
                    name="cstGstNo"
                    placeholder="Customer GST no."
                    value={formData.cstGstNo}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{error?.cstGstNo}</p>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="text-center">
            <Button
              variant="secondary"
              className="px-32"
              onClick={handleSubmit}
            >
              Save client
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddClientModel;
