import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import ToastMessage from "../../component/toast/ToastMessage";

export const getTemplatesList = createAsyncThunk(
  "getTemplatesList/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.getTemplates(), {
        method: "GET",
      });
      // ToastMessage(response);

      dispatch(loadingEnd());
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching getTemplatesList:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getMyCampaignList = createAsyncThunk(
  "getMyCampaignList/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.getCampaignTemplate(), {
        method: "GET",
      });
      // ToastMessage(response);

      dispatch(loadingEnd());
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching getTemplatesList:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getClientBussinessData = createAsyncThunk(
  "getClientBussinessData/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.getTemplateDetails(), {
        method: "GET",
      });
      // ToastMessage(response);

      dispatch(loadingEnd());
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching getTemplatesList:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const fetchCampaignAutomationReminder = createAsyncThunk(
  "fetchCampaignAutomationReminder/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.campaignAutomationReminder(), {
        method: "GET",
      });
      // ToastMessage(response);

      dispatch(loadingEnd());
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching getTemplatesList:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const createCampaignAutomationReminderEnquiryAdd = createAsyncThunk(
  "createCampaignAutomationReminder/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      const backend = new API();
      const response = await backend.fetch(
        API.createCampaignAutomationReminder(),
        {
          method: "POST",
          body: formData,
        }
      );
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(fetchCampaignAutomationReminder());
      }
      // You may want to return some data here if needed
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Adding Enquiry:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
