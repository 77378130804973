import { createSlice } from "@reduxjs/toolkit";
import {
  packagesList,
  packagesEdite,
  packagesDelete,
  packagesAdd,
  membershipsAdd,
  membershipsList,
  membershipsDelete,
  membershipEdite,
} from "./index";

const packagesSlice = createSlice({
  name: "membership",
  initialState: {
    packgesList: {},
    packgesListIsLoading: false,
    packageEdit: {},
    packageEditIsLoading: false,
    packageDelete: {},
    packageDeleteIsLoading: false,
    packageAdd: {},
    packageAddIsLoading: false,
    membersAdd: {},
    membersAddIsLoading: false,
    membersEdit: {},
    membersEditIsLoading: false,
    membersList: {},
    membersListIsLoading: false,
    membersDelete: {},
    membersDeleteIsLoading: false,
    error: null,
  },
  reducers: {
    clearPackageEdit: (state) => {
      state.packageEdit = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(packagesList.pending, (state) => {
        state.packgesListIsLoading = true;
      })
      .addCase(packagesList.fulfilled, (state, action) => {
        state.packgesList = action.payload;
        state.packgesListIsLoading = false;
      })
      .addCase(packagesList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.packgesListIsLoading = false;
      })
      .addCase(packagesEdite.pending, (state) => {
        state.packageEditIsLoading = true;
      })
      .addCase(packagesEdite.fulfilled, (state, action) => {
        state.packageEdit = action.payload;
        state.packageEditIsLoading = false;
      })
      .addCase(packagesEdite.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.packageEditIsLoading = false;
      })
      .addCase(packagesDelete.pending, (state) => {
        state.packageDeleteIsLoading = true;
      })
      .addCase(packagesDelete.fulfilled, (state, action) => {
        state.packageDelete = action.payload;
        state.packageDeleteIsLoading = false;
      })
      .addCase(packagesDelete.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.packageDeleteIsLoading = false;
      })
      .addCase(packagesAdd.pending, (state) => {
        state.packageAddIsLoading = true;
      })
      .addCase(packagesAdd.fulfilled, (state, action) => {
        state.packageAdd = action.payload;
        state.packageAddIsLoading = false;
      })
      .addCase(packagesAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.packageAddIsLoading = false;
      })
      .addCase(membershipsAdd.pending, (state) => {
        state.membersAddIsLoading = true;
      })
      .addCase(membershipsAdd.fulfilled, (state, action) => {
        state.membersAdd = action.payload;
        state.membersAddIsLoading = false;
      })
      .addCase(membershipsAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.membersAddIsLoading = false;
      })
      .addCase(membershipsList.pending, (state) => {
        state.membersListIsLoading = true;
      })
      .addCase(membershipsList.fulfilled, (state, action) => {
        state.membersList = action.payload;
        state.membersListIsLoading = false;
      })
      .addCase(membershipsList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.membersListIsLoading = false;
      })
      .addCase(membershipEdite.pending, (state) => {
        state.membersEditIsLoading = true;
      })
      .addCase(membershipEdite.fulfilled, (state, action) => {
        state.membersEdit = action.payload;
        state.membersEditIsLoading = false;
      })
      .addCase(membershipEdite.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.membersEditIsLoading = false;
      })
      .addCase(membershipsDelete.pending, (state) => {
        state.membersDeleteIsLoading = true;
      })
      .addCase(membershipsDelete.fulfilled, (state, action) => {
        state.membersDelete = action.payload;
        state.membersDeleteIsLoading = false;
      })
      .addCase(membershipsDelete.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.membersDeleteIsLoading = false;
      });
  },
});
export const { clearPackageEdit } = packagesSlice.actions;
export default packagesSlice.reducer;
