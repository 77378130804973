import React, { useEffect, useState } from "react";
import { Col, Form, Offcanvas, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { isObjectEmpty } from "../../utils/commonFunctions";
import { FaChevronRight } from "react-icons/fa";
import { fetchAllBranches, switchToBranchById } from "../../redux/branch";
import { clearSwitchBranchStatus, setSelectedBranchId } from "../../redux/branch/branchSlice";
import EditBranchDetailes from "./EditBranchDetailes";
import AddBranch from "./AddBranch";

function BranchList({
  showOffcanvas1,
  setShowOffcanvas1,
  selectedExpenseEntry,
  refresh,
}) {
  const dispatch = useDispatch();

  const allBranches = useSelector((state) => state?.branch?.branches);
  const selectedBranchId = useSelector((state) => state?.branch?.selectedBranchId);
  const fetchAllBranchStatus = useSelector(
    (state) => state?.branch?.fetchAllBranchStatus
  );
  const switchBranchStatus = useSelector(
    (state) => state?.branch?.switchBranchStatus
  );

  useEffect(() => {
    if (allBranches && isObjectEmpty(allBranches)) {
      dispatch(fetchAllBranches({}));
    }
  }, [allBranches, dispatch]);

  //  Function to toggle the visibility of Offcanvas 1 - Edit branch
  const toggleEditBranchDetailesOffcanvas = (item) => {
    setselectedBarnchEntry(item);
    setshowEditBranchDetailesOffCanvas(!showEditBranchDetailesOffCanvas);
  };

  // Function to toggle the visibility of  Offcanvas 3 -add branch
  const toggleAddBranchOffCanvas = () => {
    setshowAddBranchOffCanvas(!showAddBranchOffCanvas);
  };

  const [showEditBranchDetailesOffCanvas, setshowEditBranchDetailesOffCanvas] =
    useState(false);
  const [selectedBarnchEntry, setselectedBarnchEntry] = useState({});
  const [showAddBranchOffCanvas, setshowAddBranchOffCanvas] = useState(false);

  // const switchBranch = (branchId) => {
  //   if (switchBranchStatus !== "loading") {
  //     dispatch(switchToBranchById(branchId));
  //   }
  // };
  const switchBranch = (branchId) => {
    // if (switchBranchStatus !== "loading") {
    //   dispatch(switchToBranchById(branchId));
    // }
    dispatch(setSelectedBranchId(branchId));
    // console.log({selectedBranchId});
    refresh();
  };

  useEffect(() => {
    if (switchBranchStatus === "succeeded") {
      dispatch(clearSwitchBranchStatus());
      refresh();
    }
  }, [switchBranchStatus, dispatch]);
  return (
    <div>
      <Offcanvas
        show={showOffcanvas1}
        onHide={() => setShowOffcanvas1(false)}
        placement={"end"}
        className="margin-top-72px"
        scroll={false}
      >
        <Offcanvas.Header className="py-4 border-bottom" closeButton={false}>
          <div className="hstack w-full">
            <p className="">Branches</p>
            <p
              className="ms-auto text-secondary cursor-pointer"
              onClick={() => toggleAddBranchOffCanvas()}
            >
              + New Branch
            </p>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="h-full vstack">
            <Form className="pb-18">
              <Row className="gy-6 align-items-center mb-8">
                {allBranches?.data?.data?.map((item, index) => {
                  return (
                    <Col sm={12} className="m-0 p-0">
                      <Form.Group>
                        <Stack
                          direction="horizontal"
                          className={`border-bottom p-4 ${
                            !item?.status && "opacity-50"
                          }`}
                        >
                          <div className="pe-4">
                            {" "}
                            <Form.Check
                              id="Female"
                              label="Female"
                              variant="secondary"
                            >
                              <Form.Check.Input
                                name="gender"
                                type="radio"
                                className="secondary-radio cursor-pointer"
                                value="Female"
                                // onChange={handleInputChange}
                                // checked={item?.isSelected}
                                checked={item?.id === selectedBranchId}
                                disabled={!item?.status}
                                onClick={() => switchBranch(item?.id)}
                              />
                              {/* <Form.Check.Label className="ms-1">Female</Form.Check.Label> */}
                            </Form.Check>
                          </div>
                          <div>
                            {" "}
                            <div>
                              <h6>{item?.branch_name}</h6>
                              <small>
                                {item?.address} {item?.city} {item?.state}{" "}
                                {item?.pincode}
                                {/* Block A, Mayapuri, opposite Rajmandir, New Delhi -
                              110053 */}
                              </small>
                            </div>
                            <p
                              className="text-secondary text-sm cursor-pointer"
                              onClick={() => {
                                if (item?.status) {
                                  toggleEditBranchDetailesOffcanvas(item);
                                }
                              }}
                            >
                              Edit details{" "}
                              <FaChevronRight className="text-xs" />
                            </p>
                          </div>
                        </Stack>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </div>
          {/* <div className="mt-auto position-absolute bottom-0 bg-white p-6 w-full start-0 overlap-20">
            <Button
              variant="secondary"
              className="w-full"
              onClick={(e) => handleSubmit(e)}
              disabled={editExpenseStatus === "loading"}
            >
              Save changes
            </Button>
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
      <EditBranchDetailes
        showOffcanvas1={showEditBranchDetailesOffCanvas}
        setShowOffcanvas1={setshowEditBranchDetailesOffCanvas}
        selectedEntry={selectedBarnchEntry}
        refresh={refresh}
      />
      <AddBranch
        showOffcanvas1={showAddBranchOffCanvas}
        setShowOffcanvas1={setshowAddBranchOffCanvas}
        refresh={refresh}
      />
    </div>
  );
}

export default BranchList;
