import React, { useState, useEffect, useContext } from "react";
import CommonLayout from "../../component/commonLayout";
import {
  Row,
  Col,
  Alert,
  Accordion,
  Card,
  ListGroup,
  Form,
  useAccordionButton,
  AccordionContext,
  Stack,
  Button,
} from "react-bootstrap";
import { getStaff } from "../../redux/appointment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCheckoutDetails,
  updateRecordSale,
} from "../../redux/sales/recordSaleActions";
import { clearRecordSalesData } from "../../redux/sales/recordSaleSlice";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../component/commonLayout/PageLoader";
import { assignWebStaffPermissionById, getAllStaffPermissionbyId } from "../../redux/staff";
import {
  FaChevronDown,
  FaChevronUp,
  FaClipboard,
} from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { clearAssignWebStaffPermission } from "../../redux/staff/staffSlice";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Stack
      direction="horizontal"
      className="border-0"
      onClick={decoratedOnClick}
    >
      <h6>{children}</h6>
      {isCurrentEventKey ? (
        <FaChevronUp className="ms-auto" />
      ) : (
        <FaChevronDown className="ms-auto" />
      )}
    </Stack>
  );
}
const PermissionList = ({
  sectionIndex,
  permissionIndex,
  permission,
  handleToggle,
}) => {
  return (
    <div className="permission-list-container">
      <Row>
        <p className="fw-bold text-sm mb-4">{permission?.label}</p>
        {permission?.permissionList?.map((item, itemIndex) => (
       
          <Stack
            direction="horizontal"
            key={item?.key}
            className=" py-2 border-bottom"
          >
            <h6 className="text-dark text-sm">{item?.label}</h6>
            <Form.Check
              type="checkbox"
              id={`${sectionIndex}-${permissionIndex}-${item?.key}`}
              // label={`${sectionIndex}-${permissionIndex}-${item?.key}`}
              // label={item.label}
              checked={item?.isSelected}
              className="ms-auto"
              onChange={() =>
                handleToggle(sectionIndex, permissionIndex, itemIndex)
              }
            />
          </Stack>
        ))}
      </Row>
    </div>
  );
};

const AddOperatorPermissions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const checkoutDetails = useSelector((state) => state.record.checkoutDetails);
  const getAllStaffPermissionList = useSelector(
    (state) => state?.staff?.getAllStaffPermissionList
  );
  const staffAsOperatorData = useSelector(
    (state) => state?.staff?.staffAsOperatorData
  );
  const errors = useSelector((state) => state.record.error);

  const assignWebStaffPermission = useSelector(
    (state) => state?.staff?.assignWebStaffPermission
  );

  const [allPermissionsList, setallPermissionsList] = useState([]);

  const [selectedSection, setSelectedSection] = useState(0);
  const [selectedPermission, setSelectedPermission] = useState(0);

  const handleSectionClick = (sectionIndex, permissionIndex) => {
    setSelectedSection(sectionIndex);
    setSelectedPermission(permissionIndex);
  };
  useEffect(() => {
    if (getAllStaffPermissionList.length) {
      setallPermissionsList(getAllStaffPermissionList);
    }
  }, [getAllStaffPermissionList]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  useEffect(() => {
    // if (addedRecordSale && isObjectEmpty(addedRecordSale)) {
    if (assignWebStaffPermission?.message) {
      // if (
      //   addedRecordSale.data.id &&
      //   addedRecordSale?.data?.hasServicesOrPackages === true
      // ) {
      //   setcheckOutId(addedRecordSale?.data?.id);
      //   setPdfUrl(addedRecordSale?.data?.invoice_url);
      //   setLgShow(true);
      // } else if (
      //   addedRecordSale.data.id &&
      //   addedRecordSale?.data?.hasServicesOrPackages === false
      // ) {
      //   navigate("/sales/entries");
      // }
      // alert("hello");
      navigate("/staff/stafflist");

      dispatch(clearAssignWebStaffPermission());
    }
    // }
  }, [assignWebStaffPermission]);

  const allStaff = useSelector((state) => state?.appointment?.allStaff);
  useEffect(() => {
    if (allStaff && isObjectEmpty(allStaff)) {
      dispatch(getStaff());
    }
  }, [allStaff, dispatch]);
  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (!id) {
      navigate("/staff/stafflist");
    } else {
      dispatch(getAllStaffPermissionbyId(id)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          // setAllCheckoutDetailes(action?.payload); // Assuming payload has the form structure you need
        } else {
          console.error("Error fetching permissions data:", action.payload);
        }
      });
    }
  }, [id, dispatch, navigate]);


  const handleToggle = (sectionIndex, permissionIndex, itemIndex) => {
    // Create a deep copy of the permissions array
    const updatedPermissions = allPermissionsList.map((section, sIndex) => {
      if (sIndex !== sectionIndex) return section; // return the section as is if not the target section

      return {
        ...section,
        permissions: section.permissions.map((permission, pIndex) => {
          if (pIndex !== permissionIndex) return permission; // return the permission as is if not the target permission

          return {
            ...permission,
            permissionList: permission.permissionList.map((item, iIndex) => {
              if (iIndex !== itemIndex) return item; // return the item as is if not the target item

              // Toggle the isSelected value
              return {
                ...item,
                isSelected: !item.isSelected,
              };
            }),
          };
        }),
      };
    });

    setallPermissionsList(updatedPermissions); // Update state with the new, modified permissions array
  };
  const transformPermissions = (updatedPermissions) => {
    const formattedPermissions = {};
  
    updatedPermissions.forEach((section) => {
      section.permissions.forEach((permission) => {
        const formattedPermission = {};
        permission.permissionList.forEach((item) => {
          if (item.isSelected) {
            if (!formattedPermission[item.key]) {
              formattedPermission[item.key] = {};
            }
            // formattedPermission[item.key][`is_${item.type}`] = item.isSelected;
            formattedPermission[item.key][`${item.type}`] = item.isSelected;
          }
        });
  
        if (Object.keys(formattedPermission).length > 0) {
          if (section.key === permission.key) {
            formattedPermissions[section.key] = formattedPermission;
          } else {
            if (!formattedPermissions[section.key]) {
              formattedPermissions[section.key] = {};
            }
            formattedPermissions[section.key][permission.key] = formattedPermission;
          }
        }
      });
    });
  
    return formattedPermissions;
  };
  const transformPermissionsX = (permissions) => {
    const result = {};
  
    permissions.forEach((section) => {
      section.permissions.forEach((permission) => {
        const permissionObj = {};
  
        permission.permissionList.forEach((item) => {
          if (!result[permission.key]) {
            result[permission.key] = {};
          }
          if (item.tag.startsWith("is") && false) {
            result[permission.key][item.tag] = item.isSelected;
          } else {
            permissionObj[item.tag] = item.isSelected;
            result[permission.key] = { ...result[permission.key], ...permissionObj };
          }
        });
      });
    });
  
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedPayload = transformPermissions(allPermissionsList);
    console.log("formated payload:", formattedPayload);
    const data = { permission: formattedPayload};
  
    
    dispatch(assignWebStaffPermissionById({data, id})).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        navigate("/staff/stafflist");
      } else {
        console.error("Error updating record sale:", action.payload);
      }
    });
  };

  // if (loading) {
  //   return (
  //     <>
  //       <PageLoader />
  //       <CommonLayout>
  //         <Row></Row>
  //       </CommonLayout>
  //     </>
  //   );
  // }

  if (!getAllStaffPermissionList) {
    return (
      <CommonLayout>
        <Row>
          <Alert variant="danger" onClick={() => navigate("/staff/stafflist")}>
            Please select a Staff to set Permissions.
          </Alert>
        </Row>
      </CommonLayout>
    );
  }
  const handleCopyToClipboard = () => {
    // Implement your copy-to-clipboard logic here
    navigator.clipboard.writeText(`${staffAsOperatorData?.staff_login_id}/${staffAsOperatorData?.password}`);
  };
  console.log({ getAllStaffPermissionList });
  console.log({ allPermissionsList } , staffAsOperatorData);

  return (
    <div>
      <CommonLayout>
        <Stack direction="horizontal" className="gap-3 mb-6">
          <div className="flex-fill">
            <h4 className="mb-2">Manage permissions</h4>
            <p>List of permissions your operator will have on login</p>
          </div>

          <div className="hstack gap-3 ">
            <Button
              variant=""
              size="md"
              className="rounded-1 border-none text-lg"
              onClick={() => navigate("/staff/stafflist")}
            >
              <FaXmark />
            </Button>
          </div>
        </Stack>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6} className="p-0">
                <Accordion defaultActiveKey="0">
                  {allPermissionsList?.map((section, sectionIndex) => {
                    return (
                      <>
                        <Card key={section.key} className="rounded-0">
                          <Card.Header className="border-0 py-4">
                            {/* <Accordion.Toggle eventKey="0">
                        {"section.label"}
                      </Accordion.Toggle> */}
                            <ContextAwareToggle
                              eventKey={sectionIndex.toString()}
                            >
                              {section.label}
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse
                            eventKey={sectionIndex.toString()}
                          >
                            <Card.Body className="p-0">
                              {section.permissions.map(
                                (permission, permissionIndex) => (
                                  <div key={permission.key} className="mb-0">
                                    <p
                                      className={`cursor-pointer py-2 px-6 text-dark ${
                                        selectedPermission ===
                                          permissionIndex &&
                                        selectedSection === sectionIndex &&
                                        "bg-primary"
                                      }`}
                                      onClick={() =>
                                        handleSectionClick(
                                          sectionIndex,
                                          permissionIndex
                                        )
                                      }
                                    >
                                      {permission.label}
                                    </p>
                                   
                                  </div>
                                )
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </>
                    );
                  })}
                </Accordion>
              </Col>
              <Col md={6} className="p-0">
                {/* Render PermissionList outside the Accordion */}
                <div className="permission-list-outside border h-full p-4">
                  {selectedSection !== null && selectedPermission !== null && (
                    <PermissionList
                      sectionIndex={selectedSection}
                      permissionIndex={selectedPermission}
                      permission={
                        allPermissionsList[selectedSection]?.permissions[
                          selectedPermission
                        ]
                      }
                      handleToggle={handleToggle}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <div className="h-full">
              <Card>
                <Card.Body>
                  <Stack direction="verticle">
                    <div className="py-2">
                      <Stack direction="horizontal">
                        <div>
                          <p className="text-dark"><strong>LOGIN CREDENTIALS</strong></p>
                          <p className="text-sm">
                            Credentials login into the salon’s account
                          </p>
                        </div>
                        <Button
                          variant="outline-light"
                          className="bg-pink-100 p-2 text-secondary"
                          onClick={handleCopyToClipboard}
                        >
                          <FaClipboard size={20} />
                        </Button>
                      </Stack>
                    </div>
                    <div className="py-2">
                      <Stack direction="horizontal">
                        <p className="text-sm">Staff Id</p>
                        <p className="ms-auto text-sm text-dark">
                          <strong>{staffAsOperatorData?.staff_login_id}</strong>
                        </p>
                      </Stack>
                    </div>
                    <div className="py-2">
                      <Stack direction="horizontal">
                        <p className="text-sm">Password</p>
                        <p className="ms-auto text-sm text-dark">
                          <strong>{staffAsOperatorData?.password}</strong>
                        </p>
                      </Stack>
                    </div>
                  </Stack>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}></Col>
          <Col md={4}></Col>
          <Col md={4} className="pull-right">
            <Stack direction="horizontal" className="gap-3 mb-8 mt-auto">
              <Button
                variant="secondary"
                className="w-full"
                onClick={handleSubmit}
              >
                Confirm settings
              </Button>
            </Stack>
          </Col>
        </Row>
      </CommonLayout>
    </div>
  );
};

export default AddOperatorPermissions;
