import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import API from "../../apis/api";
import ToastMessage from "../../component/toast/ToastMessage";
import moment from "moment";

export const addRecordSale = createAsyncThunk(
  "addRecordSale/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, JSON.stringify(data[key]));
      }

      const backend = new API();
      const response = await backend.fetch(API.recordSaleAdd(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(listRecordSales({
          date: moment().format("DD-MM-YYYY"),
        }));
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Adding Record Sale:", error);
      return rejectWithValue(error);
    }
  }
);

export const listRecordSales = createAsyncThunk(
  "listRecordSales/get",
  async ({date, search}, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.recordSaleList(date, search), {
        method: "GET",
      });
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Record Sales:", error);
      return rejectWithValue(error);
    }
  }
);

export const editRecordSale = createAsyncThunk(
  "editRecordSale/update",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    // for (const key in data) {
    //   formData.append(key, data[key]);
    // }
      formData.append('services', data);
    const backend = new API();
    try {
      const response = await backend.fetch(API.recordInventory(id), {
        method: "POST",
        body: formData,
      });
      ToastMessage(response);
      if (response.status === true) {
        dispatch(listRecordSales({
          date: moment().format("DD-MM-YYYY"),
        }));
      }
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Editing Record Sale:", error);
      return rejectWithValue(error);
    }
  }

  
);

export const fetchRecordData = createAsyncThunk(
  "fetchRecordData/get",
  async (checkOutId, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API(); // Replace with your actual API instance
    try {
      const response = await backend.fetch(API.listRecordInventory(checkOutId), {
        method: "GET",
      });
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Record Data:", error);
      return rejectWithValue(error);
    }
  }
);

export const deleteRecordSale = createAsyncThunk(
  "deleteRecordSale/delete",
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.deleteCheckout(id), {
        method: "POST",
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(listRecordSales({
          date: moment().format("DD-MM-YYYY"),
        }));
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Deleting Record Sale:", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchCheckoutDetails = createAsyncThunk(
  "fetchCheckoutDetails/get",
  async (checkOutId, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API(); // Replace with your actual API instance
    try {
      const response = await backend.fetch(API.getCheckoutDetails(checkOutId), {
        method: "GET",
      });
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Record Data:", error);
      return rejectWithValue(error);
    }
  }
);

export const updateRecordSale = createAsyncThunk(
  "updateRecordSale/post",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, JSON.stringify(data[key]));
    }
    const backend = new API();
    try {
      const response = await backend.fetch(API.updateCheckoutDetails(id), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(listRecordSales({
          date: moment().format("DD-MM-YYYY"),
        }));
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Updating Record Sale:", error);
      return rejectWithValue(error);
    }
  }
);
