import { createSlice } from "@reduxjs/toolkit";
import {
  getTemplatesList,
  getMyCampaignList,
  getClientBussinessData,
  fetchCampaignAutomationReminder,
  createCampaignAutomationReminderEnquiryAdd,
} from "./index";

const marketingSlice = createSlice({
  name: "marketingSlice",
  initialState: {
    templatesList: {},
    myTemplatesList: {},
    templatesData: {},
    clientBusinessData: {},
    allCampaignAutomationReminder: {},
    createCampaignAutomationReminderEnquiry: {},
    error: null,
  },
  reducers: {
    templatesDataAdd: (state, action) => {
      state.templatesData = action.payload;
    },
    cleartemplatesData: (state) => {
      state.templatesData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplatesList.pending, (state) => {})
      .addCase(getTemplatesList.fulfilled, (state, action) => {
        state.templatesList = action.payload;
      })
      .addCase(getTemplatesList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(getMyCampaignList.pending, (state) => {})
      .addCase(getMyCampaignList.fulfilled, (state, action) => {
        state.myTemplatesList = action.payload;
      })
      .addCase(getMyCampaignList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(getClientBussinessData.pending, (state) => {})
      .addCase(getClientBussinessData.fulfilled, (state, action) => {
        state.clientBusinessData = action.payload;
      })
      .addCase(getClientBussinessData.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(fetchCampaignAutomationReminder.pending, (state) => {})
      .addCase(fetchCampaignAutomationReminder.fulfilled, (state, action) => {
        state.allCampaignAutomationReminder = action.payload;
      })
      .addCase(fetchCampaignAutomationReminder.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(
        createCampaignAutomationReminderEnquiryAdd.pending,
        (state) => {}
      )
      .addCase(
        createCampaignAutomationReminderEnquiryAdd.fulfilled,
        (state, action) => {
          state.createCampaignAutomationReminderEnquiry = action.payload;
        }
      )
      .addCase(
        createCampaignAutomationReminderEnquiryAdd.rejected,
        (state, action) => {
          state.error = action.payload; // Make sure to handle the rejection correctly
        }
      );
  },
});
export const { templatesDataAdd, cleartemplatesData } = marketingSlice.actions;
export default marketingSlice.reducer;
