import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
const ProfileRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // const profileUrl = process.env.REACT_APP_PROFILE_URL;
  const profileUrl ='https://profile.tapgro.com/';
  console.log(id,profileUrl);

  useEffect(() => {
    if (id) {
       // Use window.location.replace to ensure the URL is correctly replaced
       window.location.replace(`${profileUrl}profile/${id}`);
    } else {
      navigate("/"); // Redirect to home or another route if id is not available
    }
  }, [id, navigate]);

  return null;
};

export default ProfileRedirect;
