import { createSlice } from "@reduxjs/toolkit";
import { fetchAllBranches, addBranch, editBranch, switchToBranchById, fetchBranchById } from "./index";

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    branches: {},
    branchDetailes: {},
    fetchAllBranchStatus: "idle",
    addBranchStatus: "idle",
    editBranchStatus: "idle",
    switchBranchStatus: "idle",
    fetchBranchByIdStatus: "idle",
    selectedBranchId: "",
    error: null
  },
  reducers: {
    clearAddBranchStatus: (state) => {
      state.addBranchStatus = "idle";
    },
    clearEditBranchStatus: (state) => {
      state.editBranchStatus = "idle";
    },
    clearSwitchBranchStatus: (state) => {
      state.switchBranchStatus = "idle";
    },
    clearBranchDetailesStatus: (state) => {
      state.branchDetailes = [];
    },
    clearBranchDetailes: (state) => {
      state.branchDetailes = {};
      state.fetchBranchByIdStatus = "idle";
    },
    setSelectedBranchId: (state , action) => {
      state.selectedBranchId = action.payload;
      localStorage.setItem(
        "branchId",
        action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBranches.pending, (state) => {
        state.fetchAllBranchStatus = "loading";
      })
      .addCase(fetchAllBranches.fulfilled, (state, action) => {
        state.fetchAllBranchStatus = "succeeded";
        state.branches = action.payload;
      })
      .addCase(fetchAllBranches.rejected, (state, action) => {
        state.fetchAllBranchStatus = "failed";
        state.error = action.payload;
      })
      .addCase(addBranch.pending, (state) => {
        state.addBranchStatus = "loading";
      })
      .addCase(addBranch.fulfilled, (state, action) => {
        state.addBranchStatus = "succeeded";
      })
      .addCase(addBranch.rejected, (state, action) => {
        state.addBranchStatus = "failed";
        state.error = action.payload;
      })
      .addCase(editBranch.pending, (state) => {
        state.editBranchStatus = "loading";
      })
      .addCase(editBranch.fulfilled, (state, action) => {
        state.editBranchStatus = "succeeded";
      })
      .addCase(editBranch.rejected, (state, action) => {
        state.editBranchStatus = "failed";
        state.error = action.payload;
      })
      .addCase(switchToBranchById.pending, (state) => {
        state.switchBranchStatus = "loading";
      })
      .addCase(switchToBranchById.fulfilled, (state, action) => {
        state.switchBranchStatus = "succeeded";
      })
      .addCase(switchToBranchById.rejected, (state, action) => {
        state.switchBranchStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchBranchById.pending, (state) => {
        state.fetchBranchByIdStatus = "loading";
      })
      .addCase(fetchBranchById.fulfilled, (state, action) => {
        state.fetchBranchByIdStatus = "succeeded";
        state.branchDetailes = action.payload?.data;
      })
      .addCase(fetchBranchById.rejected, (state, action) => {
        state.fetchBranchByIdStatus = "failed";
        state.error = action.payload;
      });
  }
});
export const { clearEditBranchStatus, clearSwitchBranchStatus, clearAddBranchStatus , clearBranchDetailesStatus , clearBranchDetailes, setSelectedBranchId} =
branchSlice.actions;
export default branchSlice.reducer;
