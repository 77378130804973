import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { HiEye, HiPlus } from "react-icons/hi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  editRecordSale,
  fetchRecordData,
} from "../../redux/sales/recordSaleActions";
import { inventoryProductListSlice } from "../../redux/inventory";
import { FaAngleDown } from "react-icons/fa6";
import { clearEditedRecordSale } from "../../redux/sales/recordSaleSlice";
import { useNavigate } from "react-router-dom";

const ProductSelect = ({ productArray, handleChange, formData, rowIndex }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(productArray);
  const [forceUpdate, setForceUpdate] = useState(0);
  useEffect(() => {
    setFilteredProducts(
      productArray.filter((result) =>
        result.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, productArray]);
  useEffect(() => {
    setForceUpdate(forceUpdate + 1);
  }, [formData, rowIndex]);

  const setValueInTextBox = (rowIndex, product, result) => {
    setSearchQuery(result?.name || "");
    handleChange(rowIndex, product, result);
    handleChange(rowIndex, "unit", result?.unit || "milliliter");
  };
  const handleFocus = (e) => {
    const inputValue = e.target.value;
    if (inputValue) {
      e.target.select();
    }
  };
  return (
    <>
      <Dropdown
        className="bg-light dropdown-toggle-remove w-full"
        autoClose="outside"
      >
        <Dropdown.Toggle
          id="dropdown-autoclose-outside"
          className="bg-light border-0 p-0 w-full"
        >
          <InputGroup className="form-group-1 border me-auto w-full">
            {
              <FormControl
                className="border-0 "
                type="text"
                name="search"
                placeholder="Select or Search product"
                autoComplete="off"
                // value={row.product}
                // onChange={(e) =>
                //   handleChange(index, "product", e.target.value)
                // }
                value={searchQuery}
                // readOnly
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={handleFocus}
                // onBlur={() => {
                //   if (formData[rowIndex]?.product.name) {
                //     // setSearchQuery("");
                //     setSearchQuery(formData[rowIndex]?.product.name);
                //   } else {
                //     setSearchQuery("");
                //   }
                // }}
              />
            }
            <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
              <FaAngleDown />
            </InputGroup.Text>
          </InputGroup>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-full">
          {/* {productArray?.map((result, result_index) => ( */}
           {filteredProducts?.map((result, result_index) => (
            <Dropdown.Item key={result.id}>
              <Form.Check type="radio" id={`product_${result.id}`}>
                <Form.Check.Input
                  type="radio"
                  name={`product_${rowIndex}`}
                  // value={row.product}
                  checked={formData[rowIndex]?.product?.id === result.id}
                  onChange={(e) =>
                    setValueInTextBox(rowIndex, "product", result)
                  }
                />
                <Form.Check.Label className="ms-2 text-wrap">
                  {result?.name}
                </Form.Check.Label>
              </Form.Check>
            </Dropdown.Item>
          ))}
          {filteredProducts?.length === 0 && searchQuery !== "" && (
            <Dropdown.ItemText>
              No product found for given search.
            </Dropdown.ItemText>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const RecordInventoryModel = ({ show, setShow, checkOutId, pdfUrl }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editedRecordSale = useSelector(
    (state) => state?.record?.editedRecordSale
  );
  const recordData = useSelector((state) => state.record.recordData);
  const productListData = useSelector((state) => state?.inventory?.productList);

  function isObjectEmpty(productListData) {
    for (let key in productListData) {
      if (productListData.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  useEffect(() => {
    if (editedRecordSale && editedRecordSale.status === true) {
      // navigate("/sales/entries");
      setShow(false);
      dispatch(clearEditedRecordSale());
    }
  }, [editedRecordSale]);
  const [services, setServices] = useState([]);
  const [productArray, setProductArray] = useState([]);

  useEffect(() => {
    if (recordData && recordData?.data) {
      setServices(recordData.data || []);
    }
  }, [recordData]);

  // Fetch record data when checkOutId changes
  useEffect(() => {
    if (checkOutId) {
      if (recordData?.id !== checkOutId) {
        dispatch(fetchRecordData(checkOutId));
      }
    }
  }, [checkOutId, dispatch]);
  useEffect(() => {
    if (productListData && isObjectEmpty(productListData)) {
      dispatch(inventoryProductListSlice({ page: "1", per_page: "1000" }));
    }
  }, [checkOutId]);
  useEffect(() => {
    if (productListData && productListData?.data) {
      setProductArray(productListData.data || []);
    }
  }, [productListData]);
  const handleClose = () => {
    setShow(false);
    setFormData([{ service: "", product: {}, qty: 1, unit: "milliliter" }]);
  };

  const [formData, setFormData] = useState([
    { service: "", product: "", qty: 1, unit: "milliliter" },
  ]);
  const [error, setError] = useState([]);

  const handleAddRow = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index + 1, 0, {
      service: "",
      product: {},
      qty: 1,
      unit: "milliliter",
    });
    setFormData(updatedFormData);
  };
  const handleRemoveRow = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };
  const handleChange = (index, field, value) => {
    if (field === "qty") {
      value = parseFloat(value) || 0;
    }
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };
  const handleSubmit = () => {
    var newError = [];
    // setError({ ...error, ["remainingAmount"]: null });
    // Validation checks
    // if (!selectedClient) {
    //   newError.selectedClient = "Please Select A Client";
    // }
    formData?.map((item, ind) => {
      let newErrors = { service: null, product: null, qty: null, unit: null };
      if (item?.service === "") {
        newErrors.service = "Please select the service.";
      }
      if (Object.keys(item?.product).length > 0) {
        newErrors.product = "Please select a product.";
      }
      if (item?.qty === 0) {
        newErrors.qty = "Please enter quantity.";
      }
      if (item?.unit) {
        newErrors.unit = "Please select the unit.";
      }
      let errors = error;
      errors[ind] = newErrors;
      newError = errors;
    });
    if (newError.length > 0) {
      setError(newError);
      // return;
    }
    console.log("erros:", newError);
    const transformedData = formData?.reduce((result, entry) => {
      const existingService = result?.find(
        (service) => service.id === entry.service
      );

      if (existingService) {
        // Service already exists, add product_details
        existingService.product_details.push({
          product_id: entry.product.id,
          name: entry.product.name,
          usage: entry.qty,
          unit: entry.unit,
          barcode:
            entry.product.barcode.length > 0
              ? entry.product.barcode[0].barcode
              : null,
        });
      } else {
        // Service doesn't exist, create a new service object
        result.push({
          id: entry.service,
          product_details: [
            {
              product_id: entry.product.id,
              name: entry.product.name,
              usage: entry.qty,
              unit: entry.unit,
              barcode:
                entry.product.barcode.length > 0
                  ? entry.product.barcode[0].barcode
                  : null,
            },
          ],
        });
      }

      return result;
    }, []);

    const services = JSON.stringify(transformedData);
    // console.log(transformedData, services);
    dispatch(editRecordSale({ data: services, id: checkOutId }));
  };

  // const handleGenerateinvoice = () => {
  //   const newError = {};

  //   // Validation checks
  //   if (!selectedClient) {
  //     newError.selectedClient = "Please Select A Client";
  //   }

  //   if (!selectedTax) {
  //     newError.taxes = "Please Select Taxes";
  //   }

  //   if (paymentEntries.length < 1) {
  //     newError.paymentEntries = "Please Select Payment Method";
  //   }
  //   if (calculateRemainingAmount() < 0) {
  //     newError.remainingAmount = "Invaild Amount";
  //   }
  //   if (calculateRemainingAmount() > 0) {
  //     newError.remainingAmount = "Amount still pending";
  //   }
  //   if (selectedItems.length === 0) {
  //     newError.selectedItem = "Please Select Any Item";
  //   }
  //   if (billingDate === "") {
  //     newError.billingDate = "Please Select Billing date";
  //   }
  //   if (billingTime === "") {
  //     newError.billingTime = "Please Select Billing date";
  //   }
  //   // if (selectedItems.length > 0 && !additionalDiscount.value) {
  //   //   newError.additionalDiscount = "Please Enter Additional Discount";
  //   // }

  //   // If there are errors, set them and return
  //   if (Object.keys(newError).length > 0) {
  //     setError(newError);
  //     return;
  //   }
  //   const payload = {
  //     products: [],
  //     services: [],
  //     memberships: [],
  //     packages: [],
  //     taxes: taxes,
  //     coupon_code: couponCode,
  //     additonal_discount: additionalDiscount,
  //     amount_payable: payableAmount,
  //     amount_paid: calculateTotalAmount(),
  //     payment_mode: paymentEntries,
  //     amount_due: calculateRemainingAmount(),
  //     reward_points: giveRewardPoints ? countRewardPoints() : 0,
  //     client_name: selectedClient.name,
  //     client_number: parseInt(selectedClient.phone),
  //     billingDate: billingDate,
  //     billingTime: billingTime,
  //   };
  //   // Create a mapping of categories to their respective arrays
  //   const categoryMap = {
  //     Products: "products",
  //     Services: "services",
  //     Memberships: "memberships",
  //     Packages: "packages",
  //   };

  //   // Iterate through selected items to categorize them
  //   selectedItems.forEach((item) => {
  //     const categoryKey = categoryMap[item.category];
  //     if (categoryKey) {
  //       // Rename the key from "stylist" to "staff"
  //       const staffData = item.stylist.map((staff) => ({
  //         staffID: staff.id,
  //         staffName: staff.staff_name,
  //         // Include other properties if needed
  //       }));

  //       // Replace "stylist" with "staff" in the orderObject
  //       payload[categoryKey].push({
  //         ...item,
  //         staff: staffData,
  //       });
  //     } else {
  //       // Handle other categories or leave as is
  //     }
  //   });
  //   console.log(payload);
  //   // dispatch(addRecordSale(formData));
  // };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size="lg"
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <Alert
            key="success"
            variant=""
            className="py-3 px-4 text-base rounded-0 border-0 text-success bg-success-light hstack justify-content-between mb-4"
          >
            <div>
              <IoIosCheckmarkCircle className="me-2 text-lg" />
              Invoice generated successfully!
            </div>
            <div className="hstack gap-2 pull-right">
              <Button
                variant="light"
                size="sm"
                className="rounded-1 border text-secondary text-sm"
                onClick={() => window.open(pdfUrl, "_blank")}
              >
                <HiEye className="me-1" />
                View Invoice
              </Button>
            </div>
          </Alert>
          <div>
            <h4 className="mb-2">Record inventory usage</h4>
            <p className="mb-10">
              Record your inventory usage against this invoice
            </p>
          </div>
          <Table borderless hover className="mb-12">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-sm text-dark">Service name</th>
                <th className="text-sm text-dark">Product name</th>
                <th className="text-sm text-dark">Qty</th>
                <th className="text-sm text-dark">Unit</th>
                <th className="text-sm text-dark">Actions</th>
              </tr>
            </thead>
            <tbody>
              {formData.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      size="sm"
                      name="service"
                      value={row.service}
                      onChange={(e) =>
                        handleChange(index, "service", e.target.value)
                      }
                    >
                      <option>Select a service</option>
                      {services?.map((service, service_index) => (
                        <option key={service?.id} value={service?.id}>
                          {service?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>
                    <ProductSelect
                      productArray={productArray}
                      handleChange={handleChange}
                      formData={formData}
                      rowIndex={index}
                    />
                  </td>
                  <td className="d-none">
                    <Dropdown
                      className="bg-light dropdown-toggle-remove w-full"
                      autoClose="outside"
                    >
                      <Dropdown.Toggle
                        id="dropdown-autoclose-outside"
                        className="bg-light border-0 p-0 w-full"
                      >
                        <InputGroup className="form-group-1 border me-auto w-full">
                          <FormControl
                            className="border-0 "
                            type="text"
                            name="search"
                            placeholder="Select product"
                            autoComplete="off"
                            // value={row.product}
                            // onChange={(e) =>
                            //   handleChange(index, "product", e.target.value)
                            // }
                          />
                          <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                            <FaAngleDown />
                          </InputGroup.Text>
                        </InputGroup>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-full">
                        {productArray?.map((result, result_index) => (
                          <Dropdown.Item key={result.id}>
                            <Form.Check
                              type="radio"
                              id={`product_${result.id}`}
                            >
                              <Form.Check.Input
                                type="radio"
                                name="product"
                                // value={row.product}
                                checked={
                                  formData[index]?.product?.id === result.id
                                }
                                onChange={(e) =>
                                  handleChange(index, "product", result)
                                }
                              />
                              <Form.Check.Label className="ms-2 text-wrap">
                                {result?.name}
                              </Form.Check.Label>
                            </Form.Check>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Select aria-label="Default select example" size="sm">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select> */}
                  </td>
                  <td>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="65"
                      value={row.qty}
                      onChange={(e) =>
                        handleChange(index, "qty", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      size="sm"
                      name="unit"
                      defaultValue={"milliliter"}
                      value={row.unit}
                      // onChange={(e) =>
                      //   handleChange(index, "unit", e.target.value)
                      // }
                      className="d-none"
                      disabled
                    >
                      {/* <option value="">Select Unit</option> */}
                      <option value="milliliter">milliliter (ml)</option>
                      {/* <option value="litre">Litre</option> */}
                      <option value="piece">Piece</option>
                      <option value="gram">Gram</option>
                    </Form.Select>
                    <p>{row.unit}</p>
                  </td>
                  <td>
                    <div className="d-flex gap-2">
                      <Button
                        variant=""
                        className="bg-primary text-light p-0 w-6 h-6 d-flex align-items-center justify-content-center rounded-0"
                        onClick={() => handleAddRow(index)}
                      >
                        <HiPlus />
                      </Button>
                      <Button
                        variant=""
                        className="bg-secondary text-light p-0 w-6 h-6 d-flex align-items-center justify-content-center rounded-0"
                        onClick={() => handleRemoveRow(index)}
                      >
                        <RiDeleteBin5Line />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="mx-auto border-0">
          <Button variant="secondary" className="px-20" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RecordInventoryModel;
