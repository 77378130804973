import { useState } from "react";
import { Form,  InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordInput = ({ label, name, value, onChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          placeholder=""
          aria-label={name}
          type={showPassword ? "text" : "password"}
          name={name}
          value={value}
          onChange={onChange}
          aria-describedby="basic-addon1"
          className="border-end-0"
        />
        <InputGroup.Text
          id="basic-addon1"
          className="text-dark bg-light border-start-0"
          onClick={togglePasswordVisibility}
          style={{ cursor: "pointer" }}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </InputGroup.Text>
      </InputGroup>
      <p className="text-danger">{error}</p>
    </Form.Group>
  );
};

export default PasswordInput;
