import { createSlice } from "@reduxjs/toolkit";
import { fetchAllExpenses, addExpense, editExpense, deleteExpense, fetchAllExpenseCategories } from "./index";

const expensesSlice = createSlice({
  name: "expenses",
  initialState: {
    expenses: {},
    expenseCategories: {},
    fetchAllExpenseStatus: "idle",
    addExpenseStatus: "idle",
    editExpenseStatus: "idle",
    deleteExpenseStatus: "idle",
    fetchAllExpenseCategoriesStatus: "idle",
    error: null
  },
  reducers: {
    clearAddExpenseStatus: (state) => {
      state.addExpenseStatus = "idle";
    },
    clearEditExpenseStatus: (state) => {
      state.editExpenseStatus = "idle";
    },
    clearDeleteExpenseStatus: (state) => {
      state.deleteExpenseStatus = "idle";
    },
    clearExpenseCategoriesStatus: (state) => {
      state.expenseCategories = [];
    },
    clearExpenseCategories: (state) => {
      state.expenseCategories = {};
      state.fetchAllExpenseCategoriesStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllExpenses.pending, (state) => {
        state.fetchAllExpenseStatus = "loading";
      })
      .addCase(fetchAllExpenses.fulfilled, (state, action) => {
        state.fetchAllExpenseStatus = "succeeded";
        state.expenses = action.payload;
      })
      .addCase(fetchAllExpenses.rejected, (state, action) => {
        state.fetchAllExpenseStatus = "failed";
        state.error = action.payload;
      })
      .addCase(addExpense.pending, (state) => {
        state.addExpenseStatus = "loading";
      })
      .addCase(addExpense.fulfilled, (state, action) => {
        state.addExpenseStatus = "succeeded";
      })
      .addCase(addExpense.rejected, (state, action) => {
        state.addExpenseStatus = "failed";
        state.error = action.payload;
      })
      .addCase(editExpense.pending, (state) => {
        state.editExpenseStatus = "loading";
      })
      .addCase(editExpense.fulfilled, (state, action) => {
        state.editExpenseStatus = "succeeded";
      })
      .addCase(editExpense.rejected, (state, action) => {
        state.editExpenseStatus = "failed";
        state.error = action.payload;
      })
      .addCase(deleteExpense.pending, (state) => {
        state.deleteExpenseStatus = "loading";
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        state.deleteExpenseStatus = "succeeded";
      })
      .addCase(deleteExpense.rejected, (state, action) => {
        state.deleteExpenseStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllExpenseCategories.pending, (state) => {
        state.fetchAllExpenseCategoriesStatus = "loading";
      })
      .addCase(fetchAllExpenseCategories.fulfilled, (state, action) => {
        state.fetchAllExpenseCategoriesStatus = "succeeded";
        state.expenseCategories = action.payload?.data;
      })
      .addCase(fetchAllExpenseCategories.rejected, (state, action) => {
        state.fetchAllExpenseCategoriesStatus = "failed";
        state.error = action.payload;
      });
  }
});
export const { clearEditExpenseStatus, clearDeleteExpenseStatus, clearAddExpenseStatus , clearExpenseCategoriesStatus , clearExpenseCategories} =
expensesSlice.actions;
export default expensesSlice.reducer;
