import { toast } from "react-toastify";

function ToastMessage(response) {
  const options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressStyle: { background: "#F54E15" },
    progress: undefined,
    theme: "dark",
  };

  if (response.status === true) {
    toast.success(response.message, options);
  } else {
    toast.error("Error: " + response.message, options);
  }

  return null;
}

export default ToastMessage;
