import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import { Container } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function CommonLayout({ children }) {
  return (
    <main className="app-container">
      <Header />
      <div className="d-flex flex-column flex-sm-row h-sm-full">
        <Sidebar />
        <div className="flex-sm-1 overflow-y-sm-auto">
          <Container fluid className="pt-10 px-6">
            {children}
          </Container>
        </div>
      </div>
    </main>
  );
}

export default CommonLayout;
