import { createSlice } from "@reduxjs/toolkit";
import { fetchReport, fetchAllFilters } from "./index";
import moment from "moment";

const reportSlice = createSlice({
  name: "report",
  initialState: {
    reportType: "salesReports",
    subReportType: "service",
    filters: {
      start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    },
    allFilters: [],
    data: {
      fields: [],
      data: [],
    },
    status: "idle",
  },
  reducers: {
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setSubReportType: (state, action) => {
      state.subReportType = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setData: (state, action) => {
      state.data = {
        fields: [],
        data: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFilters.fulfilled, (state, action) => {
        state.allFilters = action.payload?.data;
      })
      .addCase(fetchReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload?.data;
      })
      .addCase(fetchReport.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const { setReportType, setSubReportType, setFilters, setData } =
  reportSlice.actions;
export default reportSlice.reducer;
