import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import ToastMessage from "../../component/toast/ToastMessage";
import { setSelectedBranchId } from "../branch/branchSlice";
import { setModulesPermissions } from "./authSession";

export const authSendOtp = createAsyncThunk(
  "authSession/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.authSessionCreate(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const contactUsApi = createAsyncThunk(
  "contactUsApi/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.contactUsApi(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const authVerifyOtp = createAsyncThunk(
  "authVerifyOtp/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.verifyOtp(), {
        method: "POST",
        body: formData,
      });
      // if (response.status === "False") {
      //   let logiUserDetilas = {
      //     device_type: "android ",
      //     phonecode: data?.phonecode,
      //     mobile: data?.mobile,
      //     register_as: "Business",
      //     login_as: "Owner",
      //   };
      //   dispatch(authLoginUser(logiUserDetilas));
      if (response.status === false) {
        // ToastMessage(response);
      } else if (response.status === true) {
        localStorage.setItem("token", response?.login_token);
        localStorage.setItem("branchId", response?.data?.branch_id);
        localStorage.setItem("isOwner", true);
        localStorage.setItem("selectedBranchName", response?.data?.branch_name);
        dispatch(setSelectedBranchId(response?.data?.branch_id));
        // If login is successful, fetch the profile status
        const profileStatusResponse = await backend.fetch(
          API.getProfileStatus(),
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${response.login_token}`,
              "X-Logged-branch-ID":
                localStorage.getItem("branchId") || "undefined",
            },
          }
        );

        if (profileStatusResponse.status === true) {
          // Update local storage with profile status information
          localStorage.setItem(
            "seller_name",
            profileStatusResponse?.data?.seller_name
          );
          localStorage.setItem(
            "business_logo",
            profileStatusResponse?.data?.business_logo
          );
          localStorage.setItem(
            "business_name",
            profileStatusResponse?.data?.business_name
          );
        }
        // localStorage.setItem("business_logo", response?.data?.business_logo);
        // localStorage.setItem("name", response?.data?.name);
        // localStorage.setItem("business_name", response?.data?.business_name);
      }
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error otp verify:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const authLoginUser = createAsyncThunk(
  "authLoginUser/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.loginUser(), {
        method: "POST",
        body: formData,
      });
      localStorage.setItem("token", response?.login_token);
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error login user:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const authResendOtp = createAsyncThunk(
  "authResendOtp/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.resendOtp(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error resend otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const staffOperatorLogin = createAsyncThunk(
  "staffLogin/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.staffLogin(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      console.log({ response });
      if (response?.status === true) {
        localStorage.setItem("token", response?.login_token);
        localStorage.setItem("staffId", response?.data?.staff_id);
        localStorage.setItem("branchId", response?.data?.branch_id);
        localStorage.setItem("isOwner", false);
        localStorage.setItem("selectedBranchName", response?.data?.branch_name);
        dispatch(setSelectedBranchId(response?.data?.branch_id));

        // If login is successful, fetch the profile status
        const profileStatusResponse = await backend.fetch(
          API.getProfileStatus(),
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${response.login_token}`,
              "X-Logged-branch-ID":
                localStorage.getItem("branchId") || "undefined",
            },
          }
        );

        if (profileStatusResponse.status === true) {
          // Update local storage with profile status information
          localStorage.setItem(
            "seller_name",
            profileStatusResponse?.data?.seller_name
          );
          localStorage.setItem(
            "business_logo",
            profileStatusResponse?.data?.business_logo
          );
          localStorage.setItem(
            "business_name",
            profileStatusResponse?.data?.business_name
          );
        }
        // If login is successful, fetch Permissions
        const permissionResponse = await backend.fetch(
          API.getStaffPermission(response?.data?.staff_id),
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${response.login_token}`,
              "X-Logged-branch-ID":
                localStorage.getItem("branchId") || "undefined",
            },
          }
        );

        if (permissionResponse.status === true) {
          // Update local storage with profile status information
          console.log({ permissionResponse });
          dispatch(
            setModulesPermissions(permissionResponse?.data?.permissions)
          );

          // localStorage.setItem(
          //   "seller_name",
          //   profileStatusResponse?.data?.seller_name
          // );
        }
      }

      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Loging in:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
