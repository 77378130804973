import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import API from "../../apis/api";
import ToastMessage from "../../component/toast/ToastMessage";
import { objectToParams } from "../../utils/commonFunctions";

// Fetch Reports
export const fetchReport = createAsyncThunk(
  "report/fetchReport",
  async (
    { reportType, subReportType, filters },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(loadingStart());

    try {
      const filterParams = objectToParams(filters);
      const backend = new API();
      const response = await backend.fetch(
        API.getAllReports({ reportType, subReportType, filterParams }),
        {
          method: "GET",
          params: filterParams,
        }
      );
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error fetching reports:", error);
      return rejectWithValue(error);
    }
  }
);
// Fetch all filters
export const fetchAllFilters = createAsyncThunk(
  "report/fetchAllFilters",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());

    try {
      const backend = new API();
      const response = await backend.fetch(API.getAllFiltersForReport(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error fetching reports:", error);
      return rejectWithValue(error);
    }
  }
);

// Fetch all expenses
export const fetchAllExpenseCategories = createAsyncThunk(
  "expenses/fetchAllExpenseCategories",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());

    try {
      const backend = new API();
      const response = await backend.fetch(API.getAllExpenseCategories(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error fetching Reports:", error);
      return rejectWithValue(error);
    }
  }
);

// // Add an expense
// export const addExpense = createAsyncThunk(
//   "expenses/add",
//   async (data, { rejectWithValue, dispatch }) => {
//     dispatch(loadingStart());
//     const formData = new FormData();
//     for (const key in data) {
//       formData.append(key, data[key]);
//     }
//     try {
//       const backend = new API();
//       const response = await backend.fetch(API.addExpense(), {
//         method: "POST",
//         body: formData,
//       });
//       dispatch(loadingEnd());
//       ToastMessage(response);
//       if (response.status === true) {
//         dispatch(fetchAllExpenses());
//       }
//       return response;
//     } catch (error) {
//       dispatch(loadingEnd());
//       console.error("Error adding expense:", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// // Edit an expense
// export const editExpense = createAsyncThunk(
//   "expenses/edit",
//   async ({ data, id }, { rejectWithValue, dispatch }) => {
//     dispatch(loadingStart());
//     const formData = new FormData();
//     for (const key in data) {
//       formData.append(key, data[key]);
//     }

//     try {
//       const backend = new API();
//       const response = await backend.fetch(API.editExpense(id), {
//         method: "POST",
//         body: formData,
//       });
//       dispatch(loadingEnd());
//       ToastMessage(response);
//       if (response.status === true) {
//         dispatch(fetchAllExpenses());
//       }
//       return response;
//     } catch (error) {
//       dispatch(loadingEnd());
//       console.error("Error editing expense:", error);
//       return rejectWithValue(error);
//     }
//   }
// );

// // Delete an expense
// export const deleteExpense = createAsyncThunk(
//   "expenses/delete",
//   async (id, { rejectWithValue, dispatch }) => {
//     dispatch(loadingStart());

//     try {
//       const backend = new API();
//       const response = await backend.fetch(API.deleteExpense(id), {
//         method: "POST",
//       });
//       dispatch(loadingEnd());
//       ToastMessage(response);
//       if (response.status === true) {
//         dispatch(fetchAllExpenses());
//       }
//       return response;
//     } catch (error) {
//       dispatch(loadingEnd());
//       console.error("Error deleting expense:", error);
//       return rejectWithValue(error);
//     }
//   }
// );
