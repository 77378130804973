import { createSlice } from "@reduxjs/toolkit";
import {
  authSendOtp,
  authVerifyOtp,
  authLoginUser,
  contactUsApi,
  staffOperatorLogin,
} from "./index";

const authSessionSlice = createSlice({
  name: "authSessionSlice",
  initialState: {
    sendOtp: {},
    verifyOtp: {},
    loginUser: {},
    contactUs: {},
    staffLogin: {},
    modulesPermissions: {},
    error: null,
  },
  reducers: {
    clearContactUs: (state) => {
      state.contactUs = {};
    },
    setModulesPermissions: (state, action) => { // Reducer to set permissions
      state.modulesPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSendOtp.pending, (state) => {})
      .addCase(authSendOtp.fulfilled, (state, action) => {
        state.sendOtp = action.payload;
      })
      .addCase(authSendOtp.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(authVerifyOtp.pending, (state) => {})
      .addCase(authVerifyOtp.fulfilled, (state, action) => {
        state.verifyOtp = action.payload;
      })
      .addCase(authVerifyOtp.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(authLoginUser.pending, (state) => {})
      .addCase(authLoginUser.fulfilled, (state, action) => {
        state.loginUser = action.payload;
      })
      .addCase(authLoginUser.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(contactUsApi.pending, (state) => {})
      .addCase(contactUsApi.fulfilled, (state, action) => {
        state.contactUs = action.payload;
      })
      .addCase(contactUsApi.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(staffOperatorLogin.pending, (state) => {})
      .addCase(staffOperatorLogin.fulfilled, (state, action) => {
        state.staffLogin = action.payload;
      })
      .addCase(staffOperatorLogin.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export const { clearContactUs, setModulesPermissions } = authSessionSlice.actions;
export default authSessionSlice.reducer;
