import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { getStaff } from "../../redux/appointment";
import {
  validateAddEnquiry,
  validateEditExpense,
} from "../../utils/validation";
import { EnquiryEdite } from "../../redux/enquiry";
import DateTime from "react-datetime";
import { formatDate, formatTime } from "../../utils/commonFunctions";
import { editExpense } from "../../redux/expense";

function EditExpense({
  showOffcanvas1,
  setShowOffcanvas1,
  selectedExpenseEntry,
  refresh,
}) {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    amount: "",
    date: moment().format("DD-MM-YYYY"),
    qty: 1,
    expense_type: "One-time",
    payment_mode: "Cash",
    receiver: "",
    remarks: "",
  });
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const editExpenseStatus = useSelector(
    (state) => state?.expense?.editExpenseStatus
  );

  useEffect(() => {
    if (editExpenseStatus === "succeeded") {
      setShowOffcanvas1(false);
      refresh();
    }
  }, [editExpenseStatus]);
  const fetchAllExpenseCategoriesStatus = useSelector(
    (state) => state?.expense?.fetchAllExpenseCategoriesStatus
  );
  const [expenseCategoriesList, setExpenseCategoriesList] = useState([]);
  const expenseCategories = useSelector(
    (state) => state?.expense?.expenseCategories
  );
  useEffect(() => {
    if (expenseCategories?.length) {
      setExpenseCategoriesList(expenseCategories);
    }
  }, [expenseCategories]);

  useEffect(() => {
    if (selectedExpenseEntry?._id) {
      setFormData({
        name: selectedExpenseEntry?.name || "",
        category: selectedExpenseEntry?.category || "",
        amount: selectedExpenseEntry?.amount || "",
        date: selectedExpenseEntry.date || moment().format("DD-MM-YYYY"),
        qty: selectedExpenseEntry?.qty || "",
        expense_type: selectedExpenseEntry?.expense_type || "One-time",
        payment_mode: selectedExpenseEntry?.payment_mode || "Cash",
        receiver: selectedExpenseEntry?.receiver || "",
        remarks: selectedExpenseEntry?.remarks || "",
      });
    }
  }, [selectedExpenseEntry]);

  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCategories, setFilteredCategories] = useState(
    expenseCategoriesList
  );
  useEffect(() => {
    setFilteredCategories(
      expenseCategoriesList.filter((result) =>
        result.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, expenseCategoriesList]);
  const setValueInTextBox = (result) => {
    setSearchQuery(result || "");
    handleInputChange({ target: { name: "category", value: result } });
  };
  const handleFocus = (e) => {
    const inputValue = e.target.value;
    if (inputValue) {
      e.target.select();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount" || name === "qty") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseFloat(value) || 0,
      }));
      setError({ ...error, [name]: null });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setError({ ...error, [name]: null });
    }
  };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   setError({ ...error, [name]: null });
  // };
  const handleDateChange = (name, time, date) => {
    // const formattedDate = date ? formatDate(time._d) : formatTime(time._d);
    const formattedDate = date
      ? moment(time).format("DD-MM-YYYY")
      : moment(time).format("hh:mm A");

    const momentDate = moment(
      formattedDate,
      date ? "DD-MM-YYYY" : "hh:mm A",
      true
    );
    if (!momentDate.isValid()) {
      console.log("Invalid date or time");
      return;
    }
    // Update state with the formatted date
    setFormData((prevBookAppointment) => ({
      ...prevBookAppointment,
      [name]: formattedDate,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fromError = validateEditExpense(formData);
    if (Object.keys(fromError).length > 0) {
      setError(fromError);
    } else {
      dispatch(editExpense({ data: formData, id: selectedExpenseEntry?._id }));
      setFormData({
        name: "",
        category: "",
        amount: "",
        date: moment().format("DD-MM-YYYY"),
        qty: 1,
        expense_type: "One-time",
        payment_mode: "Cash",
        receiver: "",
        remarks: "",
      });
    }
  };

  return (
    <div>
      <Offcanvas
        show={showOffcanvas1}
        onHide={() => setShowOffcanvas1(false)}
        placement={"end"}
        className="margin-top-72px"
        scroll={false}
      >
        <Offcanvas.Header className="py-4" closeButton>
          <div>
            <h5 className="text-md mb-2">Expense update</h5>
            <p className="text-xs">
              Expense entry created on:
              {moment(selectedExpenseEntry?.created_at).format(
                "DD MMM , YYYY | hh : mm A "
              )}
            </p>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="h-full vstack">
            <Form className="pb-18">
              <Row className="gy-6 align-items-center mb-8">
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>

                    <Form.Control
                      type="text"
                      name="name"
                      value={formData?.name}
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{error?.name}</p>
                  </Form.Group>
                </Col>

                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Category*</Form.Label>
                    <Dropdown
                      className="bg-light dropdown-toggle-remove w-full"
                      autoClose="outside"
                    >
                      <Dropdown.Toggle
                        id="dropdown-autoclose-outside"
                        className="bg-light border-0 p-0 w-full"
                      >
                        <InputGroup className="form-group-1 border me-auto w-full">
                          {
                            <FormControl
                              className="border-0 "
                              type="text"
                              name="category"
                              autoComplete="off"
                              // value={searchQuery}
                              value={formData?.category}
                              // readOnly
                              onChange={(e) => {
                                setSearchQuery(e.target.value);
                                handleInputChange(e);
                              }}
                              onFocus={handleFocus}
                            />
                          }
                        </InputGroup>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-full">
                        {filteredCategories?.map((result, result_index) => (
                          <Dropdown.Item key={result_index}>
                            <Form.Label
                              className="text-wrap cursor-pointer w-full"
                              onClick={() => setValueInTextBox(result)}
                            >
                              {result}
                            </Form.Label>
                          </Dropdown.Item>
                        ))}
                        {filteredCategories?.length === 0 &&
                          searchQuery !== "" && (
                            <Dropdown.ItemText>
                              No categories found for given search .
                            </Dropdown.ItemText>
                          )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p className="text-danger">{error?.category}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Amount*</Form.Label>
                    <Form.Control
                      type="text"
                      name="amount"
                      value={formData.amount}
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{error?.amount}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Date*</Form.Label>
                    <DateTime
                      value={formData?.date}
                      input={true} // Enable the input field
                      dateFormat="DD-MM-yyyy" // Date format
                      timeFormat={false} // Disable the time picker
                      onChange={(time) => handleDateChange("date", time, true)}
                      inputProps={{ placeholder: "DD-MM-YYYY" }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Qty</Form.Label>
                    <Form.Control
                      type="text"
                      name="qty"
                      value={formData.qty}
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{error?.qty}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Expense type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={handleInputChange}
                      value={formData.expense_type}
                      name="expense_type"
                      className="text-gray"
                    >
                      {/* <option value="">select Type</option> */}
                      <option value="One-time">One Time</option>
                      <option value="Recurring">Recurring</option>
                    </Form.Select>
                    <p className="text-danger">{error?.expense_type}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Payment mode</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="text-gray"
                      value={formData?.payment_mode}
                      name="payment_mode"
                      onChange={handleInputChange}
                    >
                      {/* <option value="">select Mode of payment</option> */}
                      <option value="Cash">Cash</option>
                      <option value="Online">Online</option>
                    </Form.Select>
                    <p className="text-danger">{error?.payment_mode}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Receiver</Form.Label>

                    <Form.Control
                      type="text"
                      name="receiver"
                      value={formData?.receiver}
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{error?.receiver}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.remarks}
                      name="remarks"
                      onChange={handleInputChange}
                    />
                    <p className="text-danger">{error?.remarks}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="mt-auto position-absolute bottom-0 bg-white p-6 w-full start-0 overlap-20">
            <Button
              variant="secondary"
              className="w-full"
              onClick={(e) => handleSubmit(e)}
              disabled={editExpenseStatus === "loading"}
            >
              Save changes
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default EditExpense;
