import { createSlice } from "@reduxjs/toolkit";
import {
  staffAdd,
  staffList,
  staffEdit,
  getStaffAvailabilty,
  getAllAppointments,
  getAllInvoceHistory,
  getstaffAvailabiltyCalendar,
  staffAvailabiltyCalendarAdd,
  staffAvailabiltyHours,
  getAllServicesTaken,
  addReminder,
  deleteReminder,
  staffDelete,
  getAllStaffPermissionbyId,
  assignWebStaffPermissionById,
  ChangeOperatorPassword,
} from "./index";

const staffSlice = createSlice({
  name: "staffSlice",
  initialState: {
    listStaff: {},
    listStaffIsLoading: false,
    listStaffAvailabilty: {},
    listStaffAvailabiltyIsLoading: false,
    listAllAppointments: {},
    listAllAppointmentsIsLoading: false,
    listAllInvoceHistory: {},
    listAllInvoceHistoryIsLoading: false,
    AllStaffAvailabiltyCalendar: {},
    AllStaffAvailabiltyCalendarIsLoading: false,
    AddStaffAvailabiltyCalendar: {},
    AddStaffAvailabiltyCalendarIsLoading: false,
    staffAvailabiltyHourLites: {},
    staffAvailabiltyHourLitesIsLoading: false,
    allservicesTakenList: {},
    allservicesTakenListIsLoading: false,
    addStaff: {},
    staffAsOperatorData: {},
    addStaffIsLoading: false,
    reminderAdd: {},
    reminderAddIsLoading: false,
    editStaff: {},
    editStaffIsLoading: false,
    deleteReminderData: {},
    deleteReminderDataIsLoading: false,
    deleteStaff: {},
    deleteStaffIsLoading: false,

    getAllStaffPermissionList: [],
    assignWebStaffPermission: {},
    staffOperationLoading: false,
    error: null,
  },
  reducers: {
    clearAddStaff: (state) => {
      state.addStaff = {};
    },
    setStaffAsOperatorData: (state, action) => {
      state.staffAsOperatorData = action.payload;
    },
    clearStaffAsOperatorData: (state) => {
      state.staffAsOperatorData = {};
    },
    clearAssignWebStaffPermission: (state) => {
      state.assignWebStaffPermission = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(staffAdd.pending, (state) => {
        state.addStaffIsLoading = true;
      })
      .addCase(staffAdd.fulfilled, (state, action) => {
        state.addStaff = action.payload;
        state.addStaffIsLoading = false;
      })
      .addCase(staffAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.addStaffIsLoading = false;
      })
      .addCase(staffList.pending, (state) => {
        state.listStaffIsLoading = true;
      })
      .addCase(staffList.fulfilled, (state, action) => {
        state.listStaff = action.payload;
        state.listStaffIsLoading = false;
      })
      .addCase(staffList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.listStaffIsLoading = false;
      })
      .addCase(staffEdit.pending, (state) => {
        state.editStaffIsLoading = true;
      })
      .addCase(staffEdit.fulfilled, (state, action) => {
        state.editStaff = action.payload;
        state.editStaffIsLoading = false;
      })
      .addCase(staffEdit.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.editStaffIsLoading = false;
      })
      .addCase(getStaffAvailabilty.pending, (state) => {
        state.listStaffAvailabiltyIsLoading = true;
      })
      .addCase(getStaffAvailabilty.fulfilled, (state, action) => {
        state.listStaffAvailabilty = action.payload;
        state.listStaffAvailabiltyIsLoading = false;
      })
      .addCase(getStaffAvailabilty.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.listStaffAvailabiltyIsLoading = false;
      })
      .addCase(getAllAppointments.pending, (state) => {
        state.listAllAppointmentsIsLoading = true;
      })
      .addCase(getAllAppointments.fulfilled, (state, action) => {
        state.listAllAppointments = action.payload;
        state.listAllAppointmentsIsLoading = false;
      })
      .addCase(getAllAppointments.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.listAllAppointmentsIsLoading = false;
      })
      .addCase(getAllInvoceHistory.pending, (state) => {
        state.listAllInvoceHistoryIsLoading = true;
      })
      .addCase(getAllInvoceHistory.fulfilled, (state, action) => {
        state.listAllInvoceHistory = action.payload;
        state.listAllInvoceHistoryIsLoading = false;
      })
      .addCase(getAllInvoceHistory.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.listAllInvoceHistoryIsLoading = false;
      })
      .addCase(getstaffAvailabiltyCalendar.pending, (state) => {
        state.AllStaffAvailabiltyCalendarIsLoading = true;
      })
      .addCase(getstaffAvailabiltyCalendar.fulfilled, (state, action) => {
        state.AllStaffAvailabiltyCalendar = action.payload;
        state.AllStaffAvailabiltyCalendarIsLoading = false;
      })
      .addCase(getstaffAvailabiltyCalendar.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.AllStaffAvailabiltyCalendarIsLoading = false;
      })
      .addCase(staffAvailabiltyCalendarAdd.pending, (state) => {
        state.AddStaffAvailabiltyCalendarIsLoading = true;
      })
      .addCase(staffAvailabiltyCalendarAdd.fulfilled, (state, action) => {
        state.AddStaffAvailabiltyCalendar = action.payload;
        state.AddStaffAvailabiltyCalendarIsLoading = false;
      })
      .addCase(staffAvailabiltyCalendarAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.AddStaffAvailabiltyCalendarIsLoading = false;
      })
      .addCase(staffAvailabiltyHours.pending, (state) => {
        state.staffAvailabiltyHourLitesIsLoading = true;
      })
      .addCase(staffAvailabiltyHours.fulfilled, (state, action) => {
        state.staffAvailabiltyHourLites = action.payload;
        state.staffAvailabiltyHourLitesIsLoading = false;
      })
      .addCase(staffAvailabiltyHours.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.staffAvailabiltyHourLitesIsLoading = false;
      })
      .addCase(getAllServicesTaken.pending, (state) => {
        state.allservicesTakenListIsLoading = true;
      })
      .addCase(getAllServicesTaken.fulfilled, (state, action) => {
        state.allservicesTakenList = action.payload;
        state.allservicesTakenListIsLoading = false;
      })
      .addCase(getAllServicesTaken.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allservicesTakenListIsLoading = false;
      })
      .addCase(addReminder.pending, (state) => {
        state.reminderAddIsLoading = true;
      })
      .addCase(addReminder.fulfilled, (state, action) => {
        state.reminderAdd = action.payload;
        state.reminderAddIsLoading = false;
      })
      .addCase(addReminder.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.reminderAddIsLoading = false;
      })
      .addCase(deleteReminder.pending, (state) => {
        state.deleteReminderDataIsLoading = true;
      })
      .addCase(deleteReminder.fulfilled, (state, action) => {
        state.deleteReminderData = action.payload;
        state.deleteReminderDataIsLoading = false;
      })
      .addCase(deleteReminder.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.deleteReminderDataIsLoading = false;
      })
      .addCase(staffDelete.pending, (state) => {
        state.deleteStaffIsLoading = true;
      })
      .addCase(staffDelete.fulfilled, (state, action) => {
        state.deleteStaff = action.payload;
        state.deleteStaffIsLoading = false;
      })
      .addCase(staffDelete.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.deleteStaffIsLoading = false;
      })
      .addCase(getAllStaffPermissionbyId.pending, (state) => {
        // state.listStaffIsLoading = true;
      })
      .addCase(getAllStaffPermissionbyId.fulfilled, (state, action) => {
        state.getAllStaffPermissionList = action.payload?.data;
        // state.listStaffIsLoading = false;
      })
      .addCase(getAllStaffPermissionbyId.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        // state.listStaffIsLoading = false;
      })
      .addCase(assignWebStaffPermissionById.pending, (state) => {
        // state.listStaffIsLoading = true;
      })
      .addCase(assignWebStaffPermissionById.fulfilled, (state, action) => {
        state.assignWebStaffPermission = action.payload?.data;
        // state.listStaffIsLoading = false;
      })
      .addCase(assignWebStaffPermissionById.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        // state.listStaffIsLoading = false;
      })
      .addCase(ChangeOperatorPassword.pending, (state) => {
        state.staffOperationLoading = true;
      })
      .addCase(ChangeOperatorPassword.fulfilled, (state, action) => {
        // state.deleteStaff = action.payload;
        state.staffOperationLoading = false;
      })
      .addCase(ChangeOperatorPassword.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.staffOperationLoading = false;
      });
  },
});

export const {
  clearAddStaff,
  setStaffAsOperatorData,
  clearStaffAsOperatorData,
  clearAssignWebStaffPermission,
} = staffSlice.actions;
export default staffSlice.reducer;
