import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import ToastMessage from "../../component/toast/ToastMessage";

export const packagesList = createAsyncThunk(
  "packagesList/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.packagesList(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // You may want to return some data here if needed
      // ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const packagesEdite = createAsyncThunk(
  "packagesEdite/update",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in data) {
      if (key === "services") {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
    const backend = new API();
    try {
      const response = await backend.fetch(API.packagesEdite(id), {
        method: "POST",
        body: formData,
      });
      if (response.status === true) {
        dispatch(packagesList());
      }
      dispatch(loadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);

export const packagesDelete = createAsyncThunk(
  "packagesDelete/delete",
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.packagesDelete(id), {
        method: "DELETE",
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(packagesList());
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);

export const packagesAdd = createAsyncThunk(
  "packagesAdd/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        if (key === "services") {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
      const backend = new API();
      const response = await backend.fetch(API.packageAdd(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        // dispatch(inventoryProductListSlice({ page: 1, per_page: 2 }));
        dispatch(packagesList());
      }
      // You may want to return some data here if needed
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Adding Product:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const membershipsAdd = createAsyncThunk(
  "membershipsAdd/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      // for (const key in data) {
      //   if (
      //     (key === "discount_services" ||
      //     key === "discount_products"||
      //     key === "discount_packages")
      //   ) {
      //     formData.append(key, JSON.stringify(data[key]));
      //   } else {
      //     formData.append(key, data[key]);
      //   }
      // }
      for (const key in data) {
        formData.append(key, JSON.stringify(data[key]));
      }
      const backend = new API();
      const response = await backend.fetch(API.membersAdd(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        // dispatch(inventoryProductListSlice({ page: 1, per_page: 2 }));
        dispatch(membershipsList());
      }
      // You may want to return some data here if needed
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Adding Product:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const membershipsList = createAsyncThunk(
  "membershipsList/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.membersList(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // You may want to return some data here if needed
      // ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const membershipsDelete = createAsyncThunk(
  "membershipsDelete/delete",
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.membersDelete(id), {
        method: "DELETE",
      });
      dispatch(loadingEnd());
      if (response.status === true) {
        dispatch(membershipsList());
      }
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);
export const membershipEdite = createAsyncThunk(
  "membershipEdit/update",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, JSON.stringify(data[key]));
    }
    const backend = new API();
    try {
      const response = await backend.fetch(API.membersEdit(id), {
        method: "POST",
        body: formData,
      });
      if (response.status === true) {
        dispatch(membershipsList());
      }
      dispatch(loadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);
