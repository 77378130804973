import React, { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { validateEditBranch } from "../../utils/validation";
import { clearEditBranchStatus } from "../../redux/branch/branchSlice";
import { editBranch } from "../../redux/branch";

function EditBranchDetailes({
  showOffcanvas1,
  setShowOffcanvas1,
  selectedEntry,
  refresh,
}) {
  const [formData, setFormData] = useState({
    branch_name: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
  });
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const editBranchStatus = useSelector(
    (state) => state?.branch?.editBranchStatus
  );

  useEffect(() => {
    if (editBranchStatus === "succeeded") {
      setShowOffcanvas1(false);
      dispatch(clearEditBranchStatus());
      setError({});
      // refresh();
    }
  }, [editBranchStatus]);

  useEffect(() => {
    if (selectedEntry?.id) {
      setFormData({
        branch_name: selectedEntry?.branch_name || "",
        address: selectedEntry?.address || "",
        pincode: selectedEntry?.pincode || "",
        city: selectedEntry.city || "",
        state: selectedEntry?.state || "",
      });
    }
  }, [selectedEntry]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      // setFormData((prevData) => ({
      //   ...prevData,
      //   [name]: parseFloat(value) || 0,
      // }));
      // setError({ ...error, [name]: null });

      if (value.length <= 6) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: parseFloat(value) || "",
        }));
        setError({ ...error, [name]: null });
      } else {
        // Optionally set an error message if needed
        setError({ ...error, [name]: "Pincode must be 6 digits" });
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setError({ ...error, [name]: null });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fromError = validateEditBranch(formData);
    if (Object.keys(fromError).length > 0) {
      setError(fromError);
    } else {
      dispatch(editBranch({ data: formData, id: selectedEntry?.id }));
      setFormData({
        branch_name: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
      });
      setError({});
    }
  };

  return (
    <div>
      <Offcanvas
        show={showOffcanvas1}
        onHide={() => setShowOffcanvas1(false)}
        placement={"end"}
        className="margin-top-72px"
        scroll={false}
      >
        <Offcanvas.Header className="py-4" closeButton>
          <div>
            <h5 className="text-md mb-2">Edit branch details</h5>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="h-full vstack">
            <Form className="pb-18">
              <Row className="gy-6 align-items-center mb-8">
                <Col sm={12}>
                  <Form.Group className="position-relative">
                    <Form.Label>Branch Name</Form.Label>

                    <Form.Control
                      type="text"
                      name="branch_name"
                      value={formData?.branch_name}
                      placeholder="Branch Name"
                      onChange={handleInputChange}
                      isInvalid={error?.branch_name}
                    />

                    <p className="text-danger">{error?.branch_name}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="position-relative">
                    <Form.Label>Add Location</Form.Label>

                    <Form.Control
                      type="text"
                      name="address"
                      value={formData?.address}
                      onChange={handleInputChange}
                      placeholder="Street address"
                      isInvalid={error?.address}
                    />
                    <p className="text-danger">{error?.address}</p>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="position-relative">
                    <Form.Control
                      type="text"
                      name="pincode"
                      value={formData?.pincode}
                      placeholder="Pincode"
                      onChange={handleInputChange}
                      isInvalid={error?.pincode}
                      maxLength="6" // This limits the input to 6 characters in the UI
                    />
                    <p className="text-danger">{error?.pincode}</p>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="position-relative">
                    <Form.Control
                      type="text"
                      name="city"
                      value={formData?.city}
                      placeholder="City"
                      onChange={handleInputChange}
                      isInvalid={error?.city}
                    />
                    <p className="text-danger">{error?.city}</p>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="position-relative">
                    <Form.Control
                      type="text"
                      name="state"
                      value={formData?.state}
                      placeholder="State"
                      onChange={handleInputChange}
                      isInvalid={error?.state}
                    />
                    <p className="text-danger">{error?.state}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="mt-auto position-absolute bottom-0 bg-white p-6 w-full start-0 overlap-20">
            <Button
              variant="secondary"
              className="w-full"
              onClick={(e) => handleSubmit(e)}
              disabled={editBranchStatus === "loading"}
            >
              Save changes
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default EditBranchDetailes;
