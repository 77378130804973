import React, { useEffect, useState } from "react";
import { Dropdown, Image, Stack } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { Logo, Profile } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BranchList from "../../OffCanvases/BranchList";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBranchId } from "../../../redux/branch/branchSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modulesPermissions = useSelector(
    (state) => state?.authSession?.modulesPermissions
  );
  const [isOwner, setIsOwner] = useState(localStorage.getItem("isOwner"));

  const [showBranchListOffCanvas, setshowBranchListOffCanvas] = useState(false);

  const allBranches = useSelector((state) => state?.branch?.branches);
  // useEffect(() => {
  //   if (
  //     allBranches &&
  //     allBranches.data &&
  //     allBranches.data.data &&
  //     allBranches.data.data.length > 0
  //   ) {
  //     const selectedBranch = allBranches.data.data.find(
  //       (branch) => branch.isSelected === true
  //     );

  //     if (selectedBranch) {
  //       localStorage.setItem(
  //         "selectedBranchName",
  //         selectedBranch.branch_name
  //       );
  //       // console.log(`Selected branch name "${selectedBranch.branch_name}" set in localStorage.`);
  //     } else {
  //       console.log("No branch is selected.");
  //     }
  //   }
  // }, [allBranches]);
  const selectedBranchId = useSelector(
    (state) => state?.branch?.selectedBranchId
  );
  useEffect(() => {
    if (
      allBranches &&
      allBranches.data &&
      allBranches.data.data &&
      allBranches.data.data.length > 0
    ) {
      if (selectedBranchId !== "") {
        const selectedBranch = allBranches.data.data.find(
          (branch) => branch.id === selectedBranchId
        );

        if (selectedBranch) {
          localStorage.setItem(
            "selectedBranchName",
            selectedBranch.branch_name
          );
          localStorage.setItem("branchId", selectedBranch.id);
          setUserData({
            business_logo: localStorage.getItem("business_logo") || Profile,
            name: localStorage.getItem("seller_name") || "user",
            business_name:
              localStorage.getItem("business_name") || "business name",
            branch_name: localStorage.getItem("selectedBranchName") || "",
          });
        }
        // console.log(
        //   `Selected branch name "${selectedBranch.branch_name}" set in localStorage.`,
        //   allBranches,
        //   selectedBranchId,
        //   localStorage.getItem("branchId")
        // );
      } else if (localStorage.getItem("branchId")) {
        const selectedBranch = allBranches.data.data.find(
          (branch) => branch.id === localStorage.getItem("branchId")
        );

        if (selectedBranch) {
          localStorage.setItem(
            "selectedBranchName",
            selectedBranch.branch_name
          );
          localStorage.setItem("branchId", selectedBranch.id);
          setUserData({
            business_logo: localStorage.getItem("business_logo") || Profile,
            name: localStorage.getItem("seller_name") || "user",
            business_name:
              localStorage.getItem("business_name") || "business name",
            branch_name: localStorage.getItem("selectedBranchName") || "",
          });
          dispatch(setSelectedBranchId(selectedBranch.id));
        }
      } else {
        const selectedBranch = allBranches.data.data.find(
          (branch) => branch.isSelected === true
        );

        if (selectedBranch) {
          localStorage.setItem(
            "selectedBranchName",
            selectedBranch.branch_name
          );
          localStorage.setItem("branchId", selectedBranch.id);
          setUserData({
            business_logo: localStorage.getItem("business_logo") || Profile,
            name: localStorage.getItem("seller_name") || "user",
            business_name:
              localStorage.getItem("business_name") || "business name",
            branch_name: localStorage.getItem("selectedBranchName") || "",
          });
          dispatch(setSelectedBranchId(selectedBranch.id));
        } else {
          // if none of the branch is selected in DB, set first as default branch.
          const selectedBranch = allBranches.data.data[0];
          localStorage.setItem(
            "selectedBranchName",
            selectedBranch.branch_name
          );
          localStorage.setItem("branchId", selectedBranch.id);
          setUserData({
            business_logo: localStorage.getItem("business_logo") || Profile,
            name: localStorage.getItem("seller_name") || "user",
            business_name:
              localStorage.getItem("business_name") || "business name",
            branch_name: localStorage.getItem("selectedBranchName") || "",
          });
          dispatch(setSelectedBranchId(selectedBranch.id));
        }
      }
      // else {
      //   console.log("No branch is selected.");
      // }
    }
  }, [allBranches, selectedBranchId]);
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const reload = () => {
    // localStorage.clear();
    // navigate("/login");
    window.location.reload();
  };

  const [userData, setUserData] = useState({
    business_logo: localStorage.getItem("business_logo") || Profile,
    name: localStorage.getItem("seller_name") || "user",
    business_name: localStorage.getItem("business_name") || "business name",
    branch_name: localStorage.getItem("selectedBranchName") || "",
  });
  // const userData = {
  //   business_logo: localStorage.getItem("business_logo") || Profile,
  //   name: localStorage.getItem("seller_name") || "user",
  //   business_name: localStorage.getItem("business_name") || "business name",
  //   branch_name: localStorage.getItem("selectedBranchName") || "",
  // };
  return (
    <header className="top-0 position-sticky overlap-1045">
      <Navbar className="hadder-bg p-4">
        <Navbar.Brand href="#home" className="py-0">
          <Image src={Logo} alt="tapgro" className="h-10" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Stack gap={2} direction="horizontal">
            <Image
              src={userData?.business_logo}
              alt="image not found"
              roundedCircle
              className="h-10 w-10"
            />
            <div>
              <p className="text-white mb-2 lh-none">{userData?.name}</p>
              <p className="text-white m-0 text-xs lh-none">
                {/* {userData?.business_name} */}
                {userData?.branch_name}
              </p>
            </div>

            {isOwner !== "false" && (
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-gray"
                // onClick={logout}
                onClick={() =>
                  setshowBranchListOffCanvas(!showBranchListOffCanvas)
                }
              />
            )}

            {/* Hind this icon for now */}
          </Stack>
          {/* <div className="branch-dropdown d-none">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-branches"
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-gray"
                  // onClick={logout}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/vikaspuri" className="branch-item">
                  <div>
                    <strong>Vikaspuri Branch</strong>
                    <small>
                      DG-3/355, vikaspuri, Opposite mahindra apartment, New
                      Delhi - 110018
                    </small>
                  </div>
                  <a href="#edit-vikaspuri" className="edit-link">
                    Edit details
                  </a>
                </Dropdown.Item>
                <Dropdown.Item href="#/janakpuri" className="branch-item">
                  <div>
                    <strong>Janakpuri Branch</strong>
                    <small>
                      Block A, Janakpuri, opposite Rajmandir, New Delhi - 110053
                    </small>
                  </div>
                  <a href="#edit-janakpuri" className="edit-link">
                    Edit details
                  </a>
                </Dropdown.Item>
                <Dropdown.Item href="#/mayapuri" className="branch-item">
                  <div>
                    <strong>Mayapuri Branch</strong>
                    <small>
                      Block A, Mayapuri, opposite Rajmandir, New Delhi - 110053
                    </small>
                  </div>
                  <a href="#edit-mayapuri" className="edit-link">
                    Edit details
                  </a>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="add-new">
                  <a href="#add-new-branch" className="add-link">
                    + New Branch
                  </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </Navbar.Collapse>
      </Navbar>
      <BranchList
        showOffcanvas1={showBranchListOffCanvas}
        setShowOffcanvas1={setshowBranchListOffCanvas}
        refresh={reload}
      />
    </header>
  );
};

export default Header;
