import { createSlice } from "@reduxjs/toolkit";
import {
  getStaff,
  getClient,
  getServices,
  bookAppoimentAdd,
  getBookAppoiment,
  bookAppoimentEdite,
  getAllAppointmentDetails,
  updateAppointmentStatus,
  cancelAppointment,
} from "./index";

const appointmentSlice = createSlice({
  name: "appointmentSlice",
  initialState: {
    allStaff: {},
    allStaffIsLoading: false,
    allClient: {},
    allClientIsLoading: false,
    allServices: {},
    allServicesIsLoading: false,
    bookAppoimentAdd: {},
    bookAppoimentAddIsLoading: false,
    bookAppoimentGet: {},
    bookAppoimentGetIsLoading: false,
    bookAppoimentEdite: {},
    bookAppoimentEditeIsLoading: false,
    updateAppointmentStatus: {},
    updateAppointmentStatusIsLoading: false,
    allAppointmentDetails: {},
    allAppointmentDetailsIsLoading: false,
    error: null,
  },
  reducers: {
    clearBookAppoimentAdd: (state) => {
      state.bookAppoimentAdd = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaff.pending, (state) => {
        state.allStaffIsLoading = true;
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.allStaff = action.payload;
        state.allStaffIsLoading = false;
      })
      .addCase(getStaff.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allStaffIsLoading = false;
      })
      .addCase(getClient.pending, (state) => {
        state.allClientIsLoading = true;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.allClient = action.payload;
        state.allClientIsLoading = false;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allClientIsLoading = false;
      })
      .addCase(getServices.pending, (state) => {
        state.allServicesIsLoading = true;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.allServices = action.payload;
        state.allServicesIsLoading = false;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allServicesIsLoading = false;
      })
      .addCase(bookAppoimentAdd.pending, (state) => {
        state.bookAppoimentAddIsLoading = true;
      })
      .addCase(bookAppoimentAdd.fulfilled, (state, action) => {
        state.bookAppoimentAdd = action.payload;
        state.bookAppoimentAddIsLoading = false;
      })
      .addCase(bookAppoimentAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.bookAppoimentAddIsLoading = false;
      })
      .addCase(getBookAppoiment.pending, (state) => {
        state.bookAppoimentGetIsLoading = true;
      })
      .addCase(getBookAppoiment.fulfilled, (state, action) => {
        state.bookAppoimentGet = action.payload;
        state.bookAppoimentGetIsLoading = false;
      })
      .addCase(getBookAppoiment.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.bookAppoimentGetIsLoading = false;
      })
      .addCase(bookAppoimentEdite.pending, (state) => {
        state.bookAppoimentEditeIsLoading = true;
      })
      .addCase(bookAppoimentEdite.fulfilled, (state, action) => {
        state.bookAppoimentEdite = action.payload;
        state.bookAppoimentEditeIsLoading = false;
      })
      .addCase(bookAppoimentEdite.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.bookAppoimentEditeIsLoading = false;
      })
      .addCase(updateAppointmentStatus.pending, (state) => {
        state.updateAppointmentStatusIsLoading = true;
      })
      .addCase(updateAppointmentStatus.fulfilled, (state, action) => {
        state.updateAppointmentStatus = action.payload;
        state.updateAppointmentStatusIsLoading = false;
      })
      .addCase(updateAppointmentStatus.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.updateAppointmentStatusIsLoading = false;
      })
      .addCase(cancelAppointment.pending, (state) => {})
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        state.updateAppointmentStatus = action.payload;
      })
      .addCase(cancelAppointment.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(getAllAppointmentDetails.pending, (state) => {
        state.allAppointmentDetailsIsLoading = true;
      })
      .addCase(getAllAppointmentDetails.fulfilled, (state, action) => {
        state.allAppointmentDetails = action.payload;
        state.allAppointmentDetailsIsLoading = false;
      })
      .addCase(getAllAppointmentDetails.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.allAppointmentDetailsIsLoading = false;
      });
  },
});
export const { clearBookAppoimentAdd } = appointmentSlice.actions;

export default appointmentSlice.reducer;
