import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import API from "../../apis/api";

export const InsightLits = createAsyncThunk(
  "InsightLits/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.InsightLits(data), {
        method: "GET",
      });
      // ToastMessage(response);

      dispatch(loadingEnd());
      let datas = {
        response,
        Insights: data?.tag,
      };
      // You may want to return some data here if needed
      return datas;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
