import { createSlice } from "@reduxjs/toolkit";
import { InsightLits } from "./index";

const insightSlice = createSlice({
  name: "insightSlice",
  initialState: {
    ListInsight: {},
    ListInsightIsLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(InsightLits.pending, (state) => {
        state.ListInsightIsLoading = true;
      })
      .addCase(InsightLits.fulfilled, (state, action) => {
        state.ListInsight[action.payload.Insights] = action.payload.response;
        state.ListInsightIsLoading = false;
      })
      .addCase(InsightLits.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.ListInsightIsLoading = false;
      });
  },
});

export default insightSlice.reducer;
