import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import ToastMessage from "../../component/toast/ToastMessage";

export const getStaff = createAsyncThunk(
  "getStaff/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      const response = await backend.fetch(API.getStaff(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getClient = createAsyncThunk(
  "getClient/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      const response = await backend.fetch(API.getClient(data), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getServices = createAsyncThunk(
  "getServices/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      const response = await backend.fetch(API.getServices(data), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const bookAppoimentAdd = createAsyncThunk(
  "bookAppoiment/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        if (key === "sub_service_id" || key === "package_id") {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
      const backend = new API();
      const response = await backend.fetch(API.bookAppoiment(), {
        method: "POST",
        body: formData,
      });
      if (response.status === true) {
        dispatch(getBookAppoiment({ data: "" }));
      }
      dispatch(loadingEnd());

      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getBookAppoiment = createAsyncThunk(
  "getBookAppoiment/get",
  async (
    { data, status, staff, search, date },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      const response = await backend.fetch(
        API.getBookAppoiment(status, staff, search, date),
        {
          method: "GET",
        }
      );
      dispatch(loadingEnd());
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const bookAppoimentEdite = createAsyncThunk(
  "bookAppoimentEdite/update",
  async ({ data, id, date }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in data) {
      if (key === "sub_service_id" || key === "package_id") {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }
    const backend = new API();
    try {
      const response = await backend.fetch(API.bookAppoimentEdite(id), {
        method: "POST",
        body: formData,
      });
      if (response.status === true) {
        dispatch(getBookAppoiment({ data: "", date: date }));
        dispatch(getAllAppointmentDetails());
      }
      dispatch(loadingEnd());
      ToastMessage(response);
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);

// Define the function to update appointment status
export const updateAppointmentStatus = createAsyncThunk(
  "updateAppointmentStatus/update",
  async ({ status, id, date }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart()); // Dispatch loadingStart action
    const formData = new FormData();
    formData.append("status", status);
    const backend = new API(); // Create an instance of your API class
    try {
      const response = await backend.fetch(API.updateAppointmentStatus(id), {
        method: "POST",
        body: formData,
      });
      if (response?.data?.status) {
        // If the response status is true
        dispatch(getBookAppoiment({ data: "", date: date }));
        dispatch(getAllAppointmentDetails()); // Dispatch getAllAppointmentDetails action
      }
      dispatch(loadingEnd()); // Dispatch loadingEnd action
      ToastMessage(response?.data); // Display toast message
      return response;
    } catch (error) {
      dispatch(loadingEnd()); // Dispatch loadingEnd action
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);

// Define the function to cancel appointment
export const cancelAppointment = createAsyncThunk(
  "cancelAppointment/update",
  async (
    { seller_id, appointment_id, date },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(loadingStart()); // Dispatch loadingStart action
    const formData = new FormData();
    formData.append("seller_id", seller_id);
    formData.append("appointment_id", appointment_id);
    const backend = new API(); // Create an instance of your API class
    try {
      const response = await backend.fetch(API.cancelAppointment(), {
        method: "POST",
        body: formData,
      });
      if (response.status) {
        dispatch(getBookAppoiment({ data: "", date: date }));
        dispatch(getAllAppointmentDetails());
      }
      dispatch(loadingEnd()); // Dispatch loadingEnd action
      ToastMessage(response?.data);
      return response;
    } catch (error) {
      dispatch(loadingEnd()); // Dispatch loadingEnd action
      console.error("Error Fetching Products:", error);
      return rejectWithValue(error);
    }
  }
);

export const getAllAppointmentDetails = createAsyncThunk(
  "getAllAppointmentDetails/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      const response = await backend.fetch(API.allAppointmentDetails(), {
        method: "GET",
      });
      dispatch(loadingEnd());
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
