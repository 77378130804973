import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdError } from "react-icons/md";
import { deleteExpense } from "../../redux/expense";

function DeleteExpenseModel({
  deleteModelShow,
  setDeleteModelShow,
  selectedExpenseEntry,
  refresh,
}) {
  const dispatch = useDispatch();

  const deleteExpenseStatus = useSelector(
    (state) => state?.expense?.deleteExpenseStatus
  );

  useEffect(() => {
    if (deleteExpenseStatus === "succeeded") {
      setDeleteModelShow(false);
      refresh();
    }
  }, [deleteExpenseStatus]);

  const handleDeleteExpenseClick = (id) => {
    dispatch(deleteExpense(id));
  };
  return (
    <div>
      <Modal
        centered
        size="lg"
        show={deleteModelShow}
        onHide={() => setDeleteModelShow(false)}
      >
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <MdError className="text-48 text-secondary mb-4" />
          <h4 className="mb-4">
            Are you sure you want to delete this entry?
          </h4>
          <p className="text-sm mb-8">
            Once deleted the entry can not be retained.
          </p>
          <div className="hstack gap-2 justify-content-center">
            <Button
              size="sm"
              className="px-8"
              variant="outline-dark"
              disabled={deleteExpenseStatus === 'loading'}
              onClick={() => {
                handleDeleteExpenseClick(selectedExpenseEntry?._id);
              }}
            >
              Yes
            </Button>
            <Button
              size="sm"
              className="px-8"
              variant="secondary"
              onClick={() => setDeleteModelShow(false)}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteExpenseModel;
