import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import API from "../../apis/api";
import ToastMessage from "../../component/toast/ToastMessage";

// Fetch all Branches
export const fetchAllBranches = createAsyncThunk(
  "branches/fetchAll",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());

    try {
      const backend = new API();
      const response = await backend.fetch(API.getAllBranches(), {
        method: "GET",
      });
      dispatch(loadingEnd());
    
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error fetching Branches:", error);
      return rejectWithValue(error);
    }
  }
);

// Fetch Branch
export const fetchBranchById = createAsyncThunk(
  "branches/fetchBranchById",
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());

    try {
      const backend = new API();
      const response = await backend.fetch(API.getBranch(id), {
        method: "GET",
      });
      dispatch(loadingEnd());
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error fetching Branch:", error);
      return rejectWithValue(error);
    }
  }
);

// Add Branch
export const addBranch = createAsyncThunk(
  "branches/add",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      const backend = new API();
      const response = await backend.fetch(API.addBranches(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      if (response.status === true) {
        dispatch(fetchAllBranches());
      }
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error adding expense:", error);
      return rejectWithValue(error);
    }
  }
);

// Edit Branch
export const editBranch = createAsyncThunk(
  "branches/edit",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      const backend = new API();
      const response = await backend.fetch(API.editBranches(id), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      if (response.status === true) {
        dispatch(fetchAllBranches());
      }
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error editing Branch:", error);
      return rejectWithValue(error);
    }
  }
);

// Delete an expense
export const switchToBranchById = createAsyncThunk(
  "branches/switch",
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());

    try {
      const backend = new API();
      const response = await backend.fetch(API.switchBranch(id), {
        method: "POST",
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      if (response.status === true) {
        dispatch(fetchAllBranches());
        localStorage.setItem(
          "branchId",
          id
        );
      }
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error switching branch:", error);
      return rejectWithValue(error);
    }
  }
);


