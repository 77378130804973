import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Alert, Container } from "react-bootstrap";
// import { LockFill } from "react-bootstrap-icons";
import PageLoader from "./PageLoader"; // Adjust the path as needed
import { BsLockFill } from "react-icons/bs";
import CommonLayout from ".";

const PermissionGuard = ({ hasPermission, children, useLayout = true }) => {
  const navigate = useNavigate();
  const isOwner = JSON.parse(localStorage.getItem("isOwner"));

  const renderContent = (content) => {
    return useLayout ? <CommonLayout>{content}</CommonLayout> : content;
  };

  // Skip permission check if the user is the owner
  if (isOwner) {
    return <>{children}</>;
  }

  // Handle undefined permission (e.g., when data is still loading)
  if (hasPermission === undefined) {
    return renderContent(<PageLoader />);
  }

  // If permission is not granted
  if (!hasPermission) {
    return renderContent(
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
        <Row className="text-center">
          <BsLockFill size={50} className="mb-3 text-danger" />
          <h5>You don’t have permission</h5>
          <p>Your permission for this module is restricted. Check with owner for further access.</p>
        </Row>
      </Container>
    );
  }

  // If permission is granted, render children
  return <>{children}</>;
};

export default PermissionGuard;
