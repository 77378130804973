import React, { useState, useEffect, Fragment } from "react";
import CommonLayout from "../../component/commonLayout";
import {
  Card,
  CardBody,
  Tabs,
  Tab,
  Row,
  Col,
  Image,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
  Stack,
  Table,
  Form,
  FormGroup,
  Alert,
  Modal,
} from "react-bootstrap";
import { BiPlus, BiRupee, BiSearch } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import Select from "react-select";
import { IoCloseSharp } from "react-icons/io5";
import { Cash, GoldCoin, TwoCoins } from "../../assets/icons";
import { HiPlus } from "react-icons/hi";
import { IoIosCheckmarkCircle, IoMdEye } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import { getStaff } from "../../redux/appointment";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecordSale,
  fetchCheckoutDetails,
  fetchRecordData,
  updateRecordSale,
} from "../../redux/sales/recordSaleActions";
import { clearRecordSalesData } from "../../redux/sales/recordSaleSlice";
import RecordInventoryModel from "../../component/Models/RecordInventoryModel";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../apis/api";
import { api_domain, api_endpoints } from "../../apis/constants";
import { toast } from "react-toastify";
import DateTime from "react-datetime";
import {
  ShowTotalEntriesCount,
  formatDate,
  formatTime,
} from "../../utils/commonFunctions";
import AddClientModel from "../../component/Models/AddClientModel";
import { PiPlusSquare, PiPlusSquareFill } from "react-icons/pi";
import PageLoader from "../../component/commonLayout/PageLoader";

const InvoiceSummary = ({
  selectedItems,
  couponDiscount,
  additionalDiscount,
  taxRate,
  taxOption,
  setPayableAmount,
  setTaxes,
}) => {
  // console.log(
  //   selectedItems,
  //   couponDiscount,
  //   additionalDiscount,
  //   taxRate,
  //   taxOption
  // );
  const [subTotal, setSubTotal] = useState(0);
  // const [totalDiscount, setTotalDiscount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [couponDiscountAmount, setCouponDiscountAmount] = useState(0);
  const [additionalDiscountAmount, setAdditionalDiscountAmount] = useState(0);

  useEffect(() => {
    // Calculate Subtotal
    const calculatedSubTotal = selectedItems?.reduce(
      (total, item) => total + item.total,
      0
    );
    setSubTotal(calculatedSubTotal);

    // Calculate Coupon Discount
    const couponDiscountAmount =
      couponDiscount.type === "%"
        ? (couponDiscount.value / 100) * calculatedSubTotal
        : couponDiscount.value;
    setCouponDiscountAmount(couponDiscountAmount);

    // Calculate total after Coupon Discounts
    const calculatedTotalAfterCoupanDiscount =
      calculatedSubTotal - couponDiscountAmount;

    // Calculate Additional Discount
    const additionalDiscountAmount =
      additionalDiscount.type === "%"
        ? (additionalDiscount.value / 100) *
          (calculatedSubTotal - couponDiscountAmount)
        : additionalDiscount.value;
    setAdditionalDiscountAmount(additionalDiscountAmount);

    // Calculate total after Additional Discounts
    const calculatedTotalAfterAdditionalDiscount =
      calculatedTotalAfterCoupanDiscount - additionalDiscountAmount;

    // Calculate Tax based on Tax Option and Category
    const calculatedTax = selectedItems?.reduce((totalTax, item) => {
      const itemCategory = item?.category?.toLowerCase();

      // Check if the item category is taxable based on the tax option
      const isTaxable = taxOption?.toLowerCase().includes(itemCategory);
      // console.log(isTaxable, "isTaxable");
      // Calculate tax only for taxable categories
      let total = calculatedTotalAfterAdditionalDiscount; // it is for overall 18% tax calculations
      return isTaxable ? (total * taxRate) / 100 : totalTax;
      // return isTaxable ? totalTax + item.total * (taxRate / 100) : totalTax; // it is for categorywise per item tax calculation
    }, 0);

    // Update Tax Amount
    setTaxAmount(calculatedTax);
    setTaxes(calculatedTax.toString());
    // setTaxes(toString(calculatedTax));

    // Calculate Final Amount after Tax
    const calculatedFinalAmount =
      calculatedTotalAfterAdditionalDiscount + calculatedTax;
    setFinalAmount(calculatedFinalAmount);
    setPayableAmount(calculatedFinalAmount.toFixed(0));
  }, [selectedItems, couponDiscount, additionalDiscount, taxRate, taxOption]);

  return (
    <>
      <Row className="mb-4 gy-4">
        <Col md={6}>
          <p className="text-sm">Item sub-total</p>
        </Col>
        <Col md={6}>
          <p className="text-sm text-dark text-end">
            {/* <span
              className="text-decoration-line-through"
              style={{ textDecoration: "line-through" }}
            >
              6360
            </span>{" "} */}
            {subTotal.toFixed(2)}
          </p>
        </Col>
        <Col md={6}>
          <p className="text-sm">Coupon discount</p>
        </Col>
        <Col md={6}>
          <p className="text-sm text-success text-end">
            {couponDiscount.type === "%"
              ? `-${couponDiscount.value}% (${couponDiscountAmount})`
              : `-Rs${couponDiscount.value}`}
          </p>
        </Col>
        <Col md={6}>
          <p className="text-sm">Additional discount</p>
        </Col>
        <Col md={6}>
          <p className="text-sm text-success text-end">
            {additionalDiscount.type === "%"
              ? `-${additionalDiscount.value}% (${additionalDiscountAmount})`
              : `-Rs${additionalDiscount.value}`}
          </p>
        </Col>
        <Col md={6}>
          <p className="text-sm">Taxes</p>
        </Col>
        <Col md={6}>
          <p className="text-sm text-end">+{taxAmount.toFixed(2)}</p>
          {/* <p className="text-sm text-success text-end">{taxRate}</p> */}
        </Col>
        <Col md={6}>
          <p className="text-sm text-dark font-semibold">Amount Payable</p>
        </Col>
        <Col md={6}>
          <p className="text-sm text-dark text-end font-semibold">
            {finalAmount.toFixed(2)}
          </p>
        </Col>
      </Row>
    </>
  );
};

const AmountInputForm = ({
  selectedClient,
  paymentEntries,
  setPaymentEntries,
  payableAmount,
  error,
  setError,
}) => {
  const [entries, setEntries] = useState([...paymentEntries]);
  const [totalAmount, setTotalAmount] = useState(payableAmount);
  const [remainingAmountState, setRemainingAmountState] =
    useState(payableAmount);

  const calculateRemainingAmount = () => {
    const remainingAmount =
      totalAmount - entries.reduce((sum, entry) => sum + entry.amount, 0);
    setRemainingAmountState(remainingAmount);
    return remainingAmount;
  };
  useEffect(() => {
    setTotalAmount(parseFloat(payableAmount) || 0);
    calculateRemainingAmount();
  }, [payableAmount]);
  useEffect(() => {
    calculateRemainingAmount();
    if (entries.length < 1) {
      setEntries([...entries, { amount: 0, payment_mode: "Cash", notes: "" }]);
    }
  }, [entries, totalAmount]);
  useEffect(() => {
    calculateRemainingAmount();
  }, []);
  useEffect(() => {
    setPaymentEntries(entries);
    setError({ ...error, ["remainingAmount"]: null });
  }, [entries]);

  // const handleInputChange = (index, field, value) => {
  //   const newEntries = [...entries];
  //   console.log("selectedClient", selectedClient?.reward_points);
  //   console.log("entry", index, field, value);

  //   if (
  //     field === "amount" &&
  //     newEntries[index].payment_mode === "RP (Reward Points)"
  //   ) {
  //     const isValidRewardPoints =
  //       selectedClient?.reward_points >= parseFloat(value);

  //     if (isValidRewardPoints || value === "") {
  //       newEntries[index] = {
  //         ...newEntries[index],
  //         [field]: parseFloat(value) || 0,
  //       };
  //     } else {
  //       // Show error for invalid reward points
  //       // You may want to handle the error appropriately, e.g., set an error state
  //       console.error("Invalid reward points");
  //       return;
  //     }
  //   } else {
  //     newEntries[index] = { ...newEntries[index], [field]: value };
  //   }

  //   setEntries(newEntries);
  // };

  const handleInputChange = (index, field, value) => {
    const newEntries = [...entries];
    // console.log("XXXXXX:", selectedClient?.reward_points, parseFloat(value));
    if (field == "amount") {
      if (newEntries[index].payment_mode === "RP (Reward Points)") {
        if (value > selectedClient?.reward_points) {
          setError({
            ...error,
            ["rewardPoint"]: "value exceeds available Rewards.",
          });
        } else {
          setError({ ...error, ["rewardPoint"]: null });
        }

        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      } else if (newEntries[index].payment_mode === "Balance") {
        if (value > selectedClient?.balance) {
          setError({
            ...error,
            ["balance"]: "value exceeds available Balance.",
          });
        } else {
          setError({ ...error, ["balance"]: null });
        }

        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      } else if (newEntries[index].payment_mode === "Membership balance") {
        if (value > selectedClient?.service_balance) {
          setError({
            ...error,
            ["membershipBalance"]:
              "value exceeds available Membership Balance.",
          });
        } else {
          setError({ ...error, ["membershipBalance"]: null });
        }

        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      } else {
        newEntries[index] = {
          ...newEntries[index],
          [field]: parseFloat(value) || 0,
        };
      }
    } else if (field == "payment_mode" && value === "RP (Reward Points)") {
      newEntries[index] = {
        ...newEntries[index],
        [field]: value,
        amount: selectedClient?.reward_points,
      };
    } else {
      newEntries[index] = { ...newEntries[index], [field]: value };
    }
    setEntries(newEntries);
  };
  // old handleInputChange method ^

  // const handleInputBlur = (index) => {
  //   const remainingAmount = totalAmount - amounts.reduce((sum, amount) => sum + amount.value, 0);

  //   if (remainingAmount > 0 && index === amounts.length - 1 && amounts[index].value !== 0) {
  //     setAmounts([...amounts, { value: 0 }]);
  //   }
  // };// does not removes extra input with 0 value in it

  const handleInputBlur = (index) => {
    return;
    const remainingAmount = calculateRemainingAmount();

    if (
      remainingAmount > 0 &&
      index === entries.length - 1 &&
      entries[index].amount !== 0
    ) {
      setEntries([...entries, { amount: 0, payment_mode: "Cash", notes: "" }]);
    } else if (entries.length !== 1) {
      const filteredEntries = entries.filter((entry, i) => entry.amount !== 0);
      setEntries(filteredEntries);
    }
  }; // removes extra input with 0 value in it
  const calculateTotalAmount = () => {
    setTotalAmount(entries.reduce((sum, amount) => sum + amount.value, 0));
  };

  const handleDeletePaymentMode = (index) => {
    setEntries((prevEntries) => {
      if (prevEntries.length === 1) {
        return prevEntries; // Do not delete if only one entry is left
      }
      return prevEntries.filter((entry, entryIndex) => entryIndex !== index);
    });
  };

  const handleAddPaymentMode = () => {
    setEntries((prevEntries) => [
      ...prevEntries,
      { amount: 0, payment_mode: "Cash", notes: "" },
    ]);
  };
  return (
    <div>
      {entries?.map((entry, index) => (
        <Fragment key={`paymentEntry-${index}`}>
          <Row className="mb-4 gy-3">
            <Col md={5}>
              <FormControl
                className="form-control-sm"
                type="text"
                name="amount"
                placeholder="0"
                value={entry.amount}
                onChange={(e) =>
                  handleInputChange(index, "amount", e.target.value)
                }
                onBlur={(e) => {
                  handleInputBlur(index);
                }}
                disabled={
                  entry.payment_mode === "RP (Reward Points)" ||
                  entry.payment_mode === "Balance" ||
                  entry.payment_mode === "Membership balance"
                }
              />
            </Col>

            <Col md={5}>
              <Form.Select
                className="form-select-sm"
                name="payment_mode"
                value={entry.payment_mode}
                onChange={(e) =>
                  handleInputChange(index, "payment_mode", e.target.value)
                }
                disabled={
                  entry.payment_mode === "RP (Reward Points)" ||
                  entry.payment_mode === "Balance" ||
                  entry.payment_mode === "Membership balance"
                }
              >
                <option
                  disabled={entries.some(
                    (item) => item.payment_mode === "Cash" && item !== entry
                  )}
                >
                  Cash
                </option>
                <option
                  disabled={entries.some(
                    (item) => item.payment_mode === "UPI" && item !== entry
                  )}
                >
                  UPI
                </option>
                <option
                  disabled={entries.some(
                    (item) => item.payment_mode === "Card" && item !== entry
                  )}
                >
                  Card
                </option>
                <option
                  // disabled={
                  //   entries.some(
                  //     (item) =>
                  //       item.payment_mode === "RP (Reward Points)" &&
                  //       item !== entry
                  //   ) ||
                  //   selectedClient?.reward_points == null ||
                  //   selectedClient?.reward_points === 0
                  // }
                  disabled
                >
                  RP (Reward Points)
                </option>
                <option
                  // disabled={
                  //   entries.some(
                  //     (item) =>
                  //       item.payment_mode === "Balance" && item !== entry
                  //   ) ||
                  //   selectedClient?.balance == null ||
                  //   selectedClient?.balance === 0
                  // }
                  disabled
                >
                  Balance
                </option>
                <option
                  // disabled={
                  //   entries.some(
                  //     (item) =>
                  //       item.payment_mode === "Membership balance" &&
                  //       item !== entry
                  //   ) ||
                  //   selectedClient?.service_balance == null ||
                  //   selectedClient?.service_balance === 0
                  // }
                  disabled
                >
                  Membership balance
                </option>
              </Form.Select>
            </Col>

            <Col md={2} className="d-flex align-items-center">
              <Button
                variant="secondary"
                size="sm"
                className=" p-2 d-flex align-items-center justify-content-center outline-none"
                onClick={() => handleDeletePaymentMode(index)}
                // disabled={entries?.length === 1}
                disabled={
                  entry.payment_mode === "RP (Reward Points)" ||
                  entry.payment_mode === "Balance" ||
                  entry.payment_mode === "Membership balance" ||
                  entries?.length === 1
                }
              >
                <RiDeleteBin5Line className="text-md" />
              </Button>
            </Col>

            {entry?.payment_mode === "UPI" && (
              <Col md={12}>
                <FormControl
                  className="form-control-sm"
                  type="text"
                  name="notes"
                  placeholder="Enter transaction ID/ notes here"
                  value={entry.notes}
                  onChange={(e) =>
                    handleInputChange(index, "notes", e.target.value)
                  }
                />
              </Col>
            )}
          </Row>

          {entry?.payment_mode === "RP (Reward Points)" && (
            <>
              <p className="text-danger">{error?.rewardPoint}</p>
              <Row className="align-items-center mb-3">
                <Col md={8} className="d-flex align-items-center">
                  <Image src={TwoCoins} className="me-2" width="24" />
                  <span className="text-sm">Available Reward points</span>
                </Col>
                <Col md={4} className="text-end">
                  <p className="text-sm text-dark font-bold">
                    {selectedClient?.reward_points || 0} points
                  </p>
                </Col>
              </Row>
            </>
          )}

          {entry?.payment_mode === "Balance" && (
            <>
              <p className="text-danger">{error?.balance}</p>
              <Row className="align-items-center mb-3">
                <Col md={8} className="d-flex align-items-center">
                  <Image src={Cash} className="me-2" width="24" />
                  <span className="text-sm">Available balance</span>
                </Col>
                <Col md={4} className="text-end">
                  <p className="text-sm text-dark font-bold">
                    Rs{selectedClient?.balance || 0}
                  </p>
                </Col>
              </Row>
            </>
          )}

          {entry?.payment_mode === "Membership balance" && (
            <>
              <p className="text-danger">{error?.membershipBalance}</p>
              <Row className="align-items-center mb-3">
                <Col md={8} className="d-flex align-items-center">
                  <Image src={GoldCoin} className="me-2" width="24" />
                  <span className="text-sm">Available Membership balance</span>
                </Col>
                <Col md={4} className="text-end">
                  <p className="text-sm text-dark font-bold">
                    {selectedClient?.service_balance || 0}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Fragment>
      ))}
      <Row>
        <Col md={12}>
          <Button
            variant="transparent"
            size="sm"
            className="w-full btn-outline-primary border-dashed rounded-1 mb-4"
            style={{ border: "1px dashed" }}
            onClick={handleAddPaymentMode}
          >
            <BiPlus className="text-md me-2" />
            Add Payment Mode
          </Button>
        </Col>
      </Row>

      <p className="text-danger">{error?.paymentEntries}</p>

      <Row className="align-items-center mb-3">
        <Col md={6}>
          <p className="text-sm">Amount due</p>
        </Col>
        <Col md={6} className="text-end">
          <p className="text-sm text-dark font-bold">
            {/* Rs{remainingAmountState.toFixed(2)} */}
            Rs{remainingAmountState}
          </p>
        </Col>
        <p className="text-danger">{error?.remainingAmount}</p>
      </Row>
    </div>
  );
};

const EditSale = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [formData, setFormData] = useState({});
  const checkoutDetails = useSelector((state) => state.record.checkoutDetails);
  const errors = useSelector((state) => state.record.error);

  // if (loading) {
  //   return <PageLoader />;
  // }

  // if (!checkoutDetails) {
  //   return (
  //     <Alert variant="danger">
  //       Please select an invoice to edit.
  //     </Alert>
  //   );
  // }
  // /////////////////////////////////////////////////////

  const addedRecordSale = useSelector(
    (state) => state?.record?.addedRecordSale
  );
  const [checkOutId, setcheckOutId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState();
  const [disableGenerateInvoiceButton, setDisableGenerateInvoiceButton] =
    useState(false);
  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  useEffect(() => {
    // if (addedRecordSale && isObjectEmpty(addedRecordSale)) {
    if (addedRecordSale.message) {
      // if (
      //   addedRecordSale.data.id &&
      //   addedRecordSale?.data?.hasServicesOrPackages === true
      // ) {
      //   setcheckOutId(addedRecordSale?.data?.id);
      //   setPdfUrl(addedRecordSale?.data?.invoice_url);
      //   setLgShow(true);
      // } else if (
      //   addedRecordSale.data.id &&
      //   addedRecordSale?.data?.hasServicesOrPackages === false
      // ) {
      //   navigate("/sales/entries");
      // }
      // alert("hello");
      navigate("/sales/entries");

      setDisableGenerateInvoiceButton(false);
      dispatch(clearRecordSalesData());
    }
    // }
  }, [addedRecordSale]);

  const clientNumber = [
    { value: "1", label: "Chocolate : nob" },
    { value: "2", label: "Strawberry" },
    { value: "3", label: "Vanilla" },
  ];
  const buttonData = ["Services", "Products", "Memberships", "Packages"];
  const [activeButton, setActiveButton] = useState("Services");
  const handleButtonClick = (buttonName) => {
    if (buttonName !== activeButton) {
      setSearchInput("");
      setSearchResults([]);
    }
    setActiveButton(buttonName);
  };

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [error, setError] = useState({});

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  // const [loading, setLoading] = useState(false); // hidden because i added same name state for edit invoice
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSearchInputChange = (e) => {
    setError({ ...error, ["selectedItem"]: null });
    setSearchInput(e.target.value);
  };
  const fetchSearchResults = async () => {
    try {
      let endpoint;
      let apiEndpoints = api_endpoints;
      switch (activeButton) {
        case "Services":
          endpoint = apiEndpoints?.getSubServicesSearch;
          break;
        case "Products":
          endpoint = apiEndpoints?.inventoryProductListGet;
          break;
        case "Memberships":
          endpoint = apiEndpoints?.membersList;
          break;
        case "Packages":
          endpoint = apiEndpoints?.packagesList;
          break;
        default:
          endpoint = apiEndpoints?.getSubServicesSearch;
          break;
      }
      setLoading(true);
      const response = await fetch(
        `${api_domain}api/V1/${endpoint}?search=${searchInput}${
          activeButton === "Packages" ? "&type=list" : ""
        }`,
        {
          headers: {
            Accept: "multipart/form-data",
            "access-control-allow-origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'X-Logged-branch-ID': localStorage.getItem("branchId") || "undefined",
          },
        }
      );
      const data = await response.json();
      setSearchResults(data.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleSelectedItem = (selectedItem) => {
    setSelectedItems((prevSelected) => {
      const isItemSelected = prevSelected.some(
        (item) =>
          item.id === selectedItem.id && item.category === selectedItem.category
      );

      if (isItemSelected) {
        return prevSelected.filter(
          (item) =>
            !(
              item.id === selectedItem.id &&
              item.category === selectedItem.category
            )
        );
      } else {
        return [...prevSelected, selectedItem];
      }
    });
  };

  const allStaff = useSelector((state) => state?.appointment?.allStaff);
  useEffect(() => {
    if (allStaff && isObjectEmpty(allStaff)) {
      dispatch(getStaff());
    }
  }, [allStaff, dispatch]);
  function isObjectEmpty(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  const countMembershipDiscount = (categoryDiscount) => {
    if (categoryDiscount === "" || selectedClient?.membership === null) {
      return { value: 0, type: "%" };
    } else {
      return selectedClient?.membership?.[`${categoryDiscount}`];
    }
  };
  const handleCheckboxChange = (result, category) => {
    // Define a mapping for each category
    const categoryKeyMap = {
      Services: {
        name: "service_subService_name",
        price: "amount",
        discount: "discount_services",
      },
      Products: { name: "name", price: "mrp", discount: "discount_products" },
      Memberships: { name: "name", price: "price", discount: "" },
      Packages: {
        name: "name",
        price: "discount_price",
        discount: "discount_packages",
      },
    };
    const totalPrice =
      parseFloat(result[categoryKeyMap[category].price].split("-")[0]) * 1;

    if (category === "Products") {
      if (result?.current_stock < 1) {
        toast.warn("No full product available in stock.");
        return;
      }
    }
    // Extract the relevant properties based on the category
    let categoryProperties = {
      ...result, // Spread other values from the result object
      id: result.id,
      category,
      name: result[categoryKeyMap[category].name],
      price: result[categoryKeyMap[category].price]?.split(" - ")[0],
      // total: undefined, // You can update this property based on your logic
      // total: totalPrice, // You can update this property based on your logic
      total: calculateTotal(
        result[categoryKeyMap[category].price],
        1,
        countMembershipDiscount(categoryKeyMap[category].discount)
      ), // You can update this property based on your logic
      quantity: 1,
      discount: countMembershipDiscount(categoryKeyMap[category].discount),
      stylist: [],
    };
    if (category === "Memberships") {
      categoryProperties = {
        ...categoryProperties,
        memberships_category: result?.category,
      };
    }
    if (category === "Packages") {
      const updatedData = result?.sub_service_info?.map((item) => ({
        ...item,
        amount: item?.amount?.split(" - ")[0],
        total: calculateTotal(item?.amount?.split(" - ")[0], 1, {
          type: "%",
          value: 0,
        }),
      }));

      categoryProperties = {
        ...categoryProperties,
        sub_service_info: updatedData,
      };
    }
    toggleSelectedItem(categoryProperties);
    // /////////////
    setSelectedServices((prevSelected) => {
      const updatedSelected = prevSelected.includes(result.id)
        ? prevSelected.filter((id) => id !== result.id)
        : [...prevSelected, result.id];

      return updatedSelected;
    });
  };
  const isSelected = (itemId, category) => {
    return selectedItems.some(
      (item) => item.id === itemId && item.category === category
    );
  };

  const [first, setfirst] = useState(1);
  useEffect(() => {
    // setPackgeInput({
    //   ...packageInput,
    //   services: selectedServices,
    //   price: totalPrice,
    // });
    setfirst(first + 1);
  }, [selectedServices, selectedItems]);
  useEffect(() => {
    let timeoutId;

    if (searchInput.trim() !== "") {
      // Clear the previous timeout
      clearTimeout(timeoutId);

      // Set a new timeout for the debounce
      timeoutId = setTimeout(() => {
        fetchSearchResults();
      }, 300);
    }

    // Cleanup the timeout on component unmount or input change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchInput]);
  const handleDeleteService = (serviceId) => {
    setSelectedServices((prevSelected) =>
      prevSelected.filter((id) => id !== serviceId)
    );
  };

  const handlePriceChange = (e, itemId) => {
    // let newPrice = parseFloat(e.target.value) || 0;
    let newPrice = e.target.value;
    if (newPrice === "" || parseFloat(newPrice) === 0) {
      toast.error("Price cannot be empty or zero.");
      // setError({
      //   ...error,
      //   ["selectedItems"]: [],
      // });
      // return;
    }
    setSelectedItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          const updatedItem = {
            ...item,
            price: newPrice,
            total: calculateTotal(newPrice, item.quantity, item.discount),
            discountPrice: calculateTotal(
              newPrice,
              item.quantity,
              item.discount
            ),
          };
          return updatedItem;
        }
        return item;
      })
    );
  };
  const handleQuantityChange = (e, itemId) => {
    let newQuantity = parseInt(e.target.value) || 0;
    // let newQuantity = parseInt(e.target.value, 10);

    // // Check if the parsed value is less than 1 or not a number, set to an empty string
    // newQuantity = newQuantity < 1 || isNaN(newQuantity) ? '' : newQuantity;

    setSelectedItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          if (item?.category === "Products") {
            let stock = item?.current_stock || 0;
            if (newQuantity > stock) {
              toast.warn(`Only ${stock} available in stock.`);
              newQuantity = stock;
              // return;
            } else {
              // return { ...item, quantity: newQuantity };
            }
          }
          const updatedItem = {
            ...item,
            quantity: newQuantity,
            total: calculateTotal(item.price, newQuantity, item.discount),
            discountPrice: calculateTotal(
              item.price,
              newQuantity,
              item.discount
            ),
          };
          return updatedItem;
        }
        return item;
      })
    );
  };
  const handleDiscountChange = (e, itemId) => {
    setError({ ...error, ["discount_value"]: null });
    const { name, value } = e.target;
    if (name === "discount_value") {
      const newValue = { value: parseInt(value) || 0 };

      setSelectedItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === itemId) {
            const newDiscount = { ...item?.discount, ...newValue };
            const updatedItem = {
              ...item,
              discount: newDiscount,
              total: calculateTotal(item.price, item.quantity, newDiscount),
              discountPrice: calculateTotal(
                item.price,
                item.quantity,
                newDiscount
              ),
            };
            setError({
              ...error,
              ["discount_value"]:
                value > 100 && item?.discount?.type === "%"
                  ? `Any Discount should not exceed 100%`
                  : null,
            });
            return updatedItem;
          }
          return item;
        })
      );
    } else if (name === "discount_type") {
      const newValue = { type: value };
      setSelectedItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === itemId) {
            const newDiscount = { ...item.discount, ...newValue };
            // console.log("Discount:", newDiscount);
            const updatedItem = {
              ...item,
              discount: newDiscount,
              total: calculateTotal(item.price, item.quantity, newDiscount),
              discountPrice: calculateTotal(
                item.price,
                item.quantity,
                newDiscount
              ),
            };
            return updatedItem;
          }
          return item;
        })
      );
    }
    // console.log( "Discount:", newDiscount);

    // const newDiscount = parseInt(e.target.value) || 0;

    // setSelectedItems((prevItems) =>
    //   prevItems.map((item) => {
    //     if (item.id === itemId) {
    //       const updatedItem = {
    //         ...item,
    //         discount: newDiscount,
    //         total: calculateTotal(item.price, item.quantity, newDiscount),
    //       };
    //       return updatedItem;
    //     }
    //     return item;
    //   })
    // );
  };

  // Helper function to calculate the total
  const calculateTotal = (price, quantity, discount) => {
    const priceString = String(price); // Ensure price is treated as string
    const parsedPrice = parseFloat(priceString.split("-")[0]);
    const totalPrice = parsedPrice * quantity;
    // price = price.split("-")[0];
    // const totalPrice = parseFloat(price) * quantity;

    if (discount.type === "%") {
      const discountedPrice = totalPrice - (totalPrice * discount.value) / 100;
      return discountedPrice;
    } else if (discount.type === "Rs") {
      const discountedPrice = totalPrice - parseFloat(discount.value);
      return discountedPrice;
    } else {
      // Handle other cases or throw an error
      throw new Error("Invalid discount type");
    }
  };

  const handleDropdownChangeForStaff = (selectedStaffId, itemId, index) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? {
              ...item,
              stylist: toggleStaff(item.stylist || [], selectedStaffId),
            }
          : item
      )
    );

    setError((prevError) => {
      const newError = { ...prevError };
      if (newError.selectedItems?.[index]?.staffError) {
        delete newError.selectedItems[index];
        if (Object.keys(newError.selectedItems).length === 0) {
          delete newError.selectedItems;
        }
      }
      return newError;
    });
    // console.log("error:", error);
    // setError({ ...error, ["stylist"]: null });
  };

  // Helper function to toggle staff selection
  const toggleStaff = (stylistArray, selectedStaffId) => {
    const index = stylistArray.findIndex(
      (staff) => staff.id === selectedStaffId
    );

    if (index !== -1) {
      // If the staff is already selected, remove it
      return [
        ...stylistArray.slice(0, index),
        ...stylistArray.slice(index + 1),
      ];
    } else {
      // If the staff is not selected, add it
      return [
        ...stylistArray,
        allStaff?.data.find((staff) => staff.id === selectedStaffId),
      ];
    }
  };
  const showStaffName = (item) => {
    return item?.map((staff) => staff?.staff_name)?.join(" + ");
  };

  const updateSubServicePrice = (item, subServiceId, enteredPrice) => {
    let price = parseFloat(enteredPrice) || 0;
    const updatedItems = selectedItems.map((selectedItem) => {
      if (
        selectedItem.id === item.id &&
        selectedItem.category === item.category
      ) {
        const updatedSubServices = selectedItem.sub_service_info.map(
          (subService) => {
            if (subService._id === subServiceId) {
              const total = calculateTotal(price, subService?.quantity, {
                type: "%",
                value: 0,
              });
              return {
                ...subService,
                amount: price,
                total,
                discountPrice: total,
              };
            }
            return subService;
          }
        );
        const packagePrice = updatedSubServices?.reduce(
          (total, serviceItem) => total + serviceItem?.total,
          0
        );

        return {
          ...selectedItem,
          sub_service_info: updatedSubServices,
          price: packagePrice,
          total: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
          discountPrice: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
        };
      }
      return selectedItem;
    });
    setSelectedItems(updatedItems);
    // updatePayableAmount(updatedItems);
  };
  const updateSubServiceStylist = (
    item,
    subServiceId,
    stylist,
    index,
    subIndex
  ) => {
    const updatedItems = selectedItems.map((selectedItem) => {
      if (
        selectedItem.id === item.id &&
        selectedItem.category === item.category
      ) {
        const updatedSubServices = selectedItem.sub_service_info.map(
          (subService) => {
            if (subService._id === subServiceId) {
              const updatedStylists = toggleStaff(
                subService.stylist || [],
                stylist.id
              );
              return { ...subService, stylist: updatedStylists };
            }
            return subService;
          }
        );
        return { ...selectedItem, sub_service_info: updatedSubServices };
      }
      return selectedItem;
    });
    setSelectedItems(updatedItems);

    setError((prevError) => {
      const newError = { ...prevError };
      if (
        newError.selectedItems?.[index]?.sub_service_info?.[subIndex]
          ?.staffError
      ) {
        delete newError.selectedItems[index].sub_service_info[subIndex]
          .staffError;
        if (
          Object.keys(newError.selectedItems[index].sub_service_info).length ===
          0
        ) {
          delete newError.selectedItems[index].sub_service_info;
        }
        if (Object.keys(newError.selectedItems[index]).length === 0) {
          delete newError.selectedItems[index];
        }
        if (Object.keys(newError.selectedItems).length === 0) {
          delete newError.selectedItems;
        }
      }
      return newError;
    });
  };

  const handleDeleteSubService = (item, subServiceId) => {
    const updatedItems = selectedItems.map((selectedItem) => {
      if (
        selectedItem.id === item.id &&
        selectedItem.category === item.category
      ) {
        const updatedSubServices = selectedItem.sub_service_info.filter(
          (subService) => subService._id !== subServiceId
        );
        const packagePrice = updatedSubServices?.reduce(
          (total, serviceItem) => total + serviceItem?.total,
          0
        );
        return {
          ...selectedItem,
          sub_service_info: updatedSubServices,
          price: packagePrice,
          total: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
          discountPrice: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
        };
      }
      return selectedItem;
    });
    setSelectedItems(updatedItems);
  };

  const updateSubServiceQuantity = (item, subServiceId, enteredQuantity) => {
    let quantity = parseFloat(enteredQuantity) || 0;
    const updatedItems = selectedItems.map((selectedItem) => {
      if (
        selectedItem.id === item.id &&
        selectedItem.category === item.category
      ) {
        const updatedSubServices = selectedItem.sub_service_info.map(
          (subService) => {
            if (subService._id === subServiceId) {
              const total = calculateTotal(subService?.amount, quantity, {
                type: "%",
                value: 0,
              });
              return { ...subService, quantity, total, discountPrice: total };
            }
            return subService;
          }
        );
        const packagePrice = updatedSubServices?.reduce(
          (total, serviceItem) => total + serviceItem?.total,
          0
        );
        return {
          ...selectedItem,
          sub_service_info: updatedSubServices,
          price: packagePrice,
          total: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
          discountPrice: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
        };
      }
      return selectedItem;
    });
    setSelectedItems(updatedItems);
    // updatePayableAmount(updatedItems);
  };
  const [packageIdToAddSubService, setPackageIdToAddSubService] = useState("");
  const [subServiceSearchList, setsubServiceSearchList] = useState([]);
  const onAddSubServiceClick = (packageId) => {
    setPackageIdToAddSubService(packageId);
  };
  const fetchSubServiceSearchResults = async (searchText) => {
    try {
      let apiEndpoints = api_endpoints;
      let endpoint = apiEndpoints?.getSubServicesSearch;
      setLoading(true);
      const response = await fetch(
        `${api_domain}api/V1/${endpoint}?search=${searchText}`,
        {
          headers: {
            Accept: "multipart/form-data",
            "access-control-allow-origin": "*",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'X-Logged-branch-ID': localStorage.getItem("branchId") || "undefined",
          },
        }
      );
      const data = await response.json();
      setsubServiceSearchList(data?.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubServiceSearch = async (e) => {
    const { value } = e.target;
    await fetchSubServiceSearchResults(value);
  };
  const handleAddSubService = (subService, packageId) => {
    // console.log("subService:", subService);
    const newSubService = {
      _id: subService?.id, // unique id for the new sub-service
      service_subService_name: subService?.service_subService_name, // default name
      amount: subService?.amount?.split(" - ")[0], // default amount
      quantity: 1,
      loyalty_point: subService?.loyalty_point, // default loyalty points
      stylist: [], // default stylist
      total: calculateTotal(subService?.amount?.split(" - ")[0], 1, {
        type: "%",
        value: 0,
      }),
    };

    const updatedItems = selectedItems.map((selectedItem) => {
      if (
        selectedItem.id === packageId &&
        selectedItem.category === "Packages"
      ) {
        const updatedSubServices = [
          ...selectedItem.sub_service_info,
          newSubService,
        ];
        const packagePrice = updatedSubServices?.reduce(
          (total, serviceItem) => total + serviceItem?.total,
          0
        );
        return {
          ...selectedItem,
          sub_service_info: updatedSubServices,
          price: packagePrice,
          total: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
          discountPrice: calculateTotal(
            packagePrice,
            selectedItem?.quantity,
            selectedItem?.discount
          ),
        };
      }
      return selectedItem;
    });
    setSelectedItems(updatedItems);
    setPackageIdToAddSubService("");
  };

  const renderRows = () => {
    return selectedItems.map((item, itemIndex) => (
      <React.Fragment key={`selectedItems${item.id}`}>
        {item.category !== "Packages" ? (
          <React.Fragment key={`items${item.id}`}>
            <tr key={`itemsRows${item.id}`}>
              <td>
                <Dropdown
                  className="bg-light dropdown-toggle-remove w-full"
                  autoClose="outside"
                >
                  <Dropdown.Toggle
                    id="dropdown-autoclose-outside"
                    // className="bg-light border-0 p-0 w-full"
                    className="bg-light border-0 p-0 w-full disabled-dropdown"
                    disabled
                  >
                    <InputGroup className="form-group-1 border me-auto w-full flex-nowrap">
                      <FormControl
                        className="border-0 flex-grow-1"
                        style={{ minWidth: "120px" }} // Ensuring a minimum width
                        type="text"
                        name="search"
                        value={showStaffName(item?.stylist)}
                        placeholder="Select staff"
                        autoComplete="off"
                        readOnly
                        disabled
                      />
                      <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                        <FaAngleDown />
                      </InputGroup.Text>
                    </InputGroup>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-full">
                    {allStaff?.data?.map((result, StaffIndex) => (
                      <Dropdown.Item key={result.id}>
                        <Form.Check type="checkbox" id={`staff_${result.id}`}>
                          <Form.Check.Input
                            type="checkbox"
                            checked={
                              item.stylist &&
                              item.stylist.some(
                                (staff) => staff.id === result.id
                              )
                            }
                            onChange={() =>
                              handleDropdownChangeForStaff(
                                result.id,
                                item.id,
                                itemIndex
                              )
                            }
                          />
                          <Form.Check.Label className="ms-2 text-wrap">
                            {result?.staff_name}
                          </Form.Check.Label>
                        </Form.Check>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                {error?.selectedItems?.[itemIndex]?.staffError && (
                  <div className="text-danger tex-wrap">
                    {error.selectedItems[itemIndex].staffError}
                  </div>
                )}
              </td>
              <td className="text-wrap">{item.name}</td>
              <td className="w-16">
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Small text"
                  className="px-1 text-center"
                  value={item?.quantity}
                  onChange={(e) => handleQuantityChange(e, item.id)}
                  // disabled
                  disabled={
                    item?.category === "Memberships" ||
                    item?.category === "Products"
                  }
                />
              </td>
              {/* <td>{`Rs${parseFloat(item?.price.split("-")[0])}`}</td> */}
              <td className="w-32">
                <InputGroup size="sm" className="d-flex flex-nowrap">
                  <InputGroup.Text
                    id="basic-addon1"
                    // className="px-1 text-dark bg-light"
                    className={`px-1 text-dark bg-light ${
                      item?.category === "Memberships" ||
                      item?.category === "Products"
                        ? "disabled-dropdown"
                        : ""
                    }`}
                  >
                    <BiRupee />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    size="sm"
                    className="text-end border-start-0 flex-grow-1 ps-0"
                    style={{ minWidth: "40px" }} // Ensuring a minimum width
                    placeholder="Enter purchase price"
                    name="purchase_price"
                    value={item?.price}
                    // value={(item?.price.split("-")[0])}
                    onChange={(e) => handlePriceChange(e, item.id)}
                    onWheel={(e) => e.target.blur()}
                    disabled={
                      item?.category === "Memberships" ||
                      item?.category === "Products"
                    }
                  />
                </InputGroup>
              </td>
              <td className="w-32">
                <InputGroup size="sm" className="d-flex flex-nowrap">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="discount_value"
                    className="border-end-0 w-1/2 form-control-sm text-center px-1"
                    style={{ minWidth: "40px" }} // Ensuring a minimum width
                    placeholder="Discount"
                    value={item?.discount?.value}
                    onChange={(e) => handleDiscountChange(e, item.id)}
                    disabled
                  />
                  <Form.Select
                    aria-label="Default select example"
                    className="pe-8 ps-0 text-end border-start-0"
                    style={{ minWidth: "50px" }} // Ensuring a minimum width
                    name="discount_type"
                    value={item?.discount?.type}
                    onChange={(e) => handleDiscountChange(e, item.id)}
                    disabled
                  >
                    <option value="%">%</option>
                    <option value="Rs">Rs</option>
                  </Form.Select>
                </InputGroup>
              </td>
              <td>
                <div className="hstack gap-2">
                  {`Rs${item.total}`}
                  {/* <Button
                    variant=""
                    className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-circle"
                    onClick={() => handleDeleteItem(itemIndex)}
                  >
                    <IoCloseSharp className="text-xs" />
                  </Button> */}
                </div>
              </td>
            </tr>{" "}
          </React.Fragment>
        ) : (
          <React.Fragment key={`packages${item.id}`}>
            <tr
              key={`packagesRows${item.id}`}
              className="bg-grey"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <td colSpan={2} className="font-weight-bold">
                <h6>{item.name}</h6>
              </td>
              <td className="w-16">
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Small text"
                  className="px-1 text-center"
                  value={item?.quantity}
                  onChange={(e) => handleQuantityChange(e, item.id)}
                  // disabled
                />
              </td>
              {/* <td>{`Rs${parseFloat(item?.price.split("-")[0])}`}</td> */}
              <td className="w-32">
                <InputGroup size="sm" className="d-flex flex-nowrap">
                  <InputGroup.Text
                    id="basic-addon1"
                    className="px-1 text-dark bg-light"
                  >
                    <BiRupee />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    size="sm"
                    className="text-end border-start-0 flex-grow-1 ps-0"
                    style={{ minWidth: "40px" }} // Ensuring a minimum width
                    placeholder="Enter purchase price"
                    name="purchase_price"
                    value={item?.price}
                    // value={(item?.price.split("-")[0])}
                    onChange={(e) => handlePriceChange(e, item.id)}
                    onWheel={(e) => e.target.blur()}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup size="sm" className="d-flex flex-nowrap">
                  <Form.Control
                    size="sm"
                    type="text"
                    name="discount_value"
                    className="border-end-0 w-1/2 form-control-sm text-center px-1"
                    style={{ minWidth: "40px" }} // Ensuring a minimum width
                    placeholder="Discount"
                    value={item?.discount?.value}
                    onChange={(e) => handleDiscountChange(e, item.id)}
                    disabled
                  />
                  <Form.Select
                    aria-label="Default select example"
                    className="pe-8 ps-0 text-end border-start-0"
                    style={{ minWidth: "50px" }} // Ensuring a minimum width
                    name="discount_type"
                    value={item?.discount?.type}
                    onChange={(e) => handleDiscountChange(e, item.id)}
                    disabled
                  >
                    <option value="%">%</option>
                    <option value="Rs">Rs</option>
                  </Form.Select>
                </InputGroup>
              </td>
              <td>
                <div className="hstack gap-2">
                  {`Rs${item.total}`}
                  {/* <Button
                    variant=""
                    className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center rounded-circle"
                    onClick={() => handleDeleteItem(itemIndex)}
                  >
                    <IoCloseSharp className="text-xs" />
                  </Button> */}
                </div>
              </td>
            </tr>{" "}
          </React.Fragment>
        )}
        {item.category === "Packages" && (
          <React.Fragment>
            {item.sub_service_info.map((subService, subIndex) => (
              <tr
                key={subService._id}
                className="sub-service-row"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <td>
                  <Dropdown
                    className="bg-light dropdown-toggle-remove w-full"
                    autoClose="outside"
                  >
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      // className="bg-light border-0 p-0 w-full"
                      className="bg-light border-0 p-0 w-full disabled-dropdown"
                      disabled
                    >
                      <InputGroup className="form-group-1 border me-auto w-full flex-nowrap">
                        <FormControl
                          className="border-0 flex-grow-1"
                          style={{ minWidth: "120px" }} // Ensuring a minimum width
                          type="text"
                          name="search"
                          value={showStaffName(subService?.stylist)}
                          placeholder="Select staff"
                          autoComplete="off"
                          readOnly
                          disabled
                        />
                        <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3">
                          <FaAngleDown />
                        </InputGroup.Text>
                      </InputGroup>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-full">
                      {allStaff?.data?.map((result, staffIndex) => (
                        <Dropdown.Item key={result.id}>
                          <Form.Check type="checkbox" id={`staff_${result.id}`}>
                            <Form.Check.Input
                              type="checkbox"
                              checked={
                                subService.stylist &&
                                subService.stylist.some(
                                  (staff) => staff.id === result.id
                                )
                              }
                              onChange={() =>
                                updateSubServiceStylist(
                                  item,
                                  subService._id,
                                  result,
                                  itemIndex,
                                  subIndex
                                )
                              }
                            />
                            <Form.Check.Label className="ms-2 text-wrap">
                              {result?.staff_name}
                            </Form.Check.Label>
                          </Form.Check>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {error?.selectedItems?.[itemIndex]?.sub_service_info?.[
                    subIndex
                  ]?.staffError && (
                    <div className="text-danger tex-wrap">
                      {
                        error.selectedItems[itemIndex].sub_service_info[
                          subIndex
                        ].staffError
                      }
                    </div>
                  )}
                </td>

                <td className="text-wrap">
                  {subService.service_subService_name}
                </td>
                <td className="w-16">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Quantity"
                    className="px-1 text-center"
                    value={subService.quantity}
                    onChange={(e) =>
                      updateSubServiceQuantity(
                        item,
                        subService._id,
                        e.target.value
                      )
                    }
                    disabled
                  />
                </td>
                <td className="w-32">
                  <InputGroup size="sm" className="d-flex flex-nowrap">
                    <InputGroup.Text className="px-1 text-dark bg-light">
                      <BiRupee />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      size="sm"
                      className="text-end border-start-0 flex-grow-1 ps-0"
                      style={{ minWidth: "40px" }} // Ensuring a minimum width
                      placeholder="Enter price"
                      name="price"
                      value={subService.amount}
                      onChange={(e) =>
                        updateSubServicePrice(
                          item,
                          subService._id,
                          e.target.value
                        )
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </InputGroup>
                </td>
                <td colSpan={1}></td>
                <td className="">
                  <div className="hstack gap-2 w-full d-flex justify-content-end">
                    {/* <p>{subService?.total}</p> */}
                    {/* <Button
                      variant=""
                      className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center"
                      onClick={() =>
                        handleDeleteSubService(item, subService._id)
                      }
                      disabled
                    >
                      <RiDeleteBin5Line className="text-xs" />
                    </Button> */}
                    {/* Hide delete button */}
                  </div>
                </td>
              </tr>
            ))}
            {packageIdToAddSubService === item.id && (
              <tr style={{ backgroundColor: "#FBFBFB" }} className="d-none">
                <td>
                  <div style={{ maxWidth: "440px" }}>
                    <Dropdown
                      className="bg-light dropdown-toggle-remove w-full"
                      autoClose="outside"
                    >
                      <Dropdown.Toggle
                        id="dropdown-autoclose-outside"
                        className="bg-light border-0 p-0 w-full"
                      >
                        <InputGroup className="form-group-1 border me-auto w-full">
                          <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3 disabled">
                            <BiSearch className="text-lg" />
                          </InputGroup.Text>

                          <FormControl
                            className="border-0 "
                            type="text"
                            name="search"
                            // value={searchInput}
                            onChange={handleSubServiceSearch}
                            placeholder="Search by name"
                            disabled={!selectedClient}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-full">
                        {subServiceSearchList?.map((result) => (
                          <Dropdown.Item key={result.id}>
                            <Form.Check
                              type="checkbox"
                              id={`service_${result.id}`}
                            >
                              <Form.Check.Input
                                type="checkbox"
                                // checked={isSelected(result.id, activeButton)}
                                onChange={() =>
                                  handleAddSubService(result, item?.id)
                                }
                                // checked={selectedServices.includes(result.id)}
                                // onChange={() => handleCheckboxChange(result)}
                              />
                              <Form.Check.Label className="ms-2 text-wrap">
                                {result?.service_subService_name}
                              </Form.Check.Label>
                            </Form.Check>
                          </Dropdown.Item>
                        ))}

                        {/* <Dropdown.Divider /> */}
                        {loading && (
                          <Dropdown.ItemText>Loading...</Dropdown.ItemText>
                        )}
                        {subServiceSearchList?.length === 0 && !loading && (
                          <Dropdown.ItemText>No result found</Dropdown.ItemText>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
                <td colSpan={5}>
                  {" "}
                  <div className="d-flex justify-content-end">
                    <Button
                      variant=""
                      className="bg-danger-light text-secondary p-0 w-5 h-5 d-flex align-items-center justify-content-center"
                      onClick={() => onAddSubServiceClick(null)}
                    >
                      <IoCloseSharp className="text-xs" />
                    </Button>
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <td
                colSpan="6"
                style={{ backgroundColor: "#FBFBFB" }}
                className="d-none"
              >
                <div className="d-flex justify-content-end">
                  <Button
                    variant="transparent"
                    size="sm"
                    className="text-primary p-0 d-flex align-items-center justify-content-center"
                    onClick={() => onAddSubServiceClick(item?.id)}
                  >
                    <PiPlusSquareFill size={24} className=" me-2" />
                    Add Service
                  </Button>
                </div>
              </td>
            </tr>
          </React.Fragment>
        )}
      </React.Fragment>
    ));
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
  };

  const calculateTotalPrice = () => {
    return selectedItems?.reduce((total, item) => total + item.total, 0);
  };

  const [clientSearchInput, setClientSearchInput] = useState("");
  const [clientSearchResults, setClientSearchResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  // const [loading, setLoading] = useState(false);

  const handleClientSearchInputChange = (e) => {
    setError({ ...error, ["selectedClient"]: null });
    setClientSearchInput(e.target.value);
  };

  const fetchClientSearchResults = async () => {
    const backend = new API();
    try {
      setLoading(true);
      const response = await backend.fetch(API.getClient(clientSearchInput), {
        headers: {
          Accept: "multipart/form-data",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'X-Logged-branch-ID': localStorage.getItem("branchId") || "undefined",
        },
      });
      const data = await response?.data;
      setClientSearchResults(data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timeoutId;

    if (clientSearchInput.trim() !== "") {
      // Clear the previous timeout
      clearTimeout(timeoutId);

      // Set a new timeout for the debounce
      timeoutId = setTimeout(() => {
        fetchClientSearchResults();
      }, 300);
    }

    // Cleanup the timeout on component unmount or input change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [clientSearchInput]);

  const [billingDate, setBillingDate] = useState(moment().format("DD-MM-YYYY"));
  const [billingTime, setBillingTime] = useState(new Date());

  const handleDateChange = (name, time, date) => {
    // const formattedDate = date ? formatDate(time._d) : formatTime(time._d);
    const formattedDate = date
      ? moment(time).format("DD-MM-YYYY")
      : moment(time).format("hh:mm A");

    const momentDate = moment(
      formattedDate,
      date ? "DD-MM-YYYY" : "hh:mm A",
      true
    );
    if (!momentDate.isValid()) {
      console.log("Invalid date or time");
      return;
    }
    // Update state with the formatted date
    if (name === "billingTime") {
      setBillingTime(formattedDate);
      setError({ ...error, ["billingtime"]: null });
    }
    if (name === "billingDate") {
      setBillingDate(formattedDate);
      setError({ ...error, ["billingDate"]: null });
    }
  };

  // useEffect(() => {
  //   const currentDate = moment();

  //   const formattedDate = currentDate.format("DD-MM-YYYY");
  //   const formattedTime = currentDate.format("HH:mm");
  //   setBillingDate(formattedDate);
  //   setBillingTime(formattedTime);
  // }, []);
  const [selectedTax, setSelectedTax] = useState();

  const taxOptionsLables = [
    { label: "0% on overall", value: "0" },
    {
      label: "18% on overall",
      value: "products services memberships packages",
    },
  ];
  // const taxOptions = ["products services", "0", "services", "products"];

  // Handle change in selected tax
  const handleTaxChange = (e) => {
    setError({ ...error, ["taxes"]: null });
    setSelectedTax(e.target.value);
  };

  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState({
    value: 0,
    type: "%", // Rs or %
  });
  // Handle change in coupon code input
  const handleCouponCodeChange = async (e) => {
    setCouponCode(e.target.value);
  };
  const checkCouponDiscount = async () => {
    if (couponCode === "") {
      return;
    }
    const backend = new API();
    try {
      setLoading(true);

      const response = await backend.fetch(API.getCoupon(couponCode), {
        headers: {
          Accept: "multipart/form-data",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'X-Logged-branch-ID': localStorage.getItem("branchId") || "undefined",
        },
      });

      const { data, status, message } = response;
      if (status) {
        setCouponDiscount({
          value: data.offer.value,
          type: data.offer.type,
          offerText: data.offer.offerText,
        });
      } else {
        console.error("Error fetching coupon details:", message);
        // Optionally, you can reset couponDiscount or handle the error in a different way
      }
    } catch (error) {
      console.error("Error fetching coupon details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCouponDetails = async (couponCode) => {
    const backend = new API();

    try {
      const response = await backend.fetch(API.getCouponDetails(couponCode), {
        headers: {
          Accept: "multipart/form-data",
          "access-control-allow-origin": "*",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'X-Logged-branch-ID': localStorage.getItem("branchId") || "undefined",
        },
      });

      return response;
    } catch (error) {
      console.error("Error fetching coupon details:", error);
      throw error; // Propagate the error to the calling function
    }
  };

  const [additionalDiscount, setAdditionalDiscount] = useState({
    value: "",
    type: "%",
  });
  const handleAdditionalDiscountChange = (key, value) => {
    setError({ ...error, ["additionalDiscount"]: null });
    if (key === "value") {
      value = value.replace(/[^0-9]/g, "");
    }
    setAdditionalDiscount((prevDiscount) => ({
      ...prevDiscount,
      [key]: value,
    }));
  };

  const [giveRewardPoints, setGiveRewardPoints] = useState(false);
  // Handle change in Give reward point input
  const handleGiveRewardPointsChange = (e) => {
    setGiveRewardPoints(e.target.checked);
  };
  const countRewardPoints = () => {
    return (
      selectedItems?.reduce((totalRewardPoints, item) => {
        if (item?.category === "Services") {
          return totalRewardPoints + parseFloat(item.loyalty_point || 0);
        } else if (item?.category === "Products") {
          return totalRewardPoints + parseFloat(item.reward_points || 0);
        }
        return totalRewardPoints; // Don't forget to handle other cases or return a default value
      }, 0) || 0
    ); // Provide an initial value for reduce and ensure the function returns a value
  };

  const [payableAmount, setPayableAmount] = useState(0);
  const [taxes, setTaxes] = useState(0);

  const [paymentEntries, setPaymentEntries] = useState([
    { payment_mode: "Cash", amount: payableAmount, notes: "" },
  ]);
  const calculateTotalAmount = () => {
    return paymentEntries?.reduce(
      (total, entry) => total + parseFloat(entry.amount || 0),
      0
    );
  };
  const calculateRemainingAmount = () => {
    const paidAmount = paymentEntries?.reduce(
      (total, entry) => total + parseFloat(entry.amount || 0),
      0
    );
    return payableAmount - paidAmount;
  };

  const handleGenerateinvoice = () => {
    // Calculate Subtotal
    const calculatedSubTotal = selectedItems?.reduce(
      (total, item) => total + item.total,
      0
    );
    const newError = {};

    // Validation checks
    if (!selectedClient) {
      newError.selectedClient = "Please Select A Client";
    }

    if (!selectedTax) {
      newError.taxes = "Please Select Taxes";
    }

    if (paymentEntries.length < 1) {
      newError.paymentEntries = "Please Select Payment Method";
    }
    // if (calculateRemainingAmount() < 0) {
    //   newError.remainingAmount = "Invalid Amount";
    // }
    // if (calculateRemainingAmount() > 0) {
    //   newError.remainingAmount = "Amount still pending";
    // }
    if (selectedItems.length === 0) {
      newError.selectedItem = "Please Select Any Item";
    }
    if (billingDate === "") {
      newError.billingDate = "Please Select Billing date";
    }
    if (billingTime === "") {
      newError.billingTime = "Please Select Billing time";
    }

    // Validate that each selected item and each sub-service in packages have at least one stylist
    selectedItems.forEach((item, itemIndex) => {
      if (item.category === "Packages") {
        item.sub_service_info.forEach((subService, subIndex) => {
          if (!subService.stylist || subService.stylist.length === 0) {
            if (!newError.selectedItems) {
              newError.selectedItems = {};
            }
            if (!newError.selectedItems[itemIndex]) {
              newError.selectedItems[itemIndex] = { sub_service_info: {} };
            }
            if (!newError.selectedItems[itemIndex].sub_service_info) {
              newError.selectedItems[itemIndex].sub_service_info = {};
            }
            newError.selectedItems[itemIndex].sub_service_info[subIndex] = {
              staffError:
                "Each sub-service within packages must have at least one stylist.",
            };
          }
        });
      } else {
        if (!item.stylist || item.stylist.length === 0) {
          if (!newError.selectedItems) {
            newError.selectedItems = {};
          }
          newError.selectedItems[itemIndex] = {
            staffError: "Each selected item must have at least one stylist.",
          };
        }
      }
    });

    // If there are errors, set them and return
    if (Object.keys(newError).length > 0) {
      setError(newError);
      return; // Ensure that function exits if there are errors
    }
    setDisableGenerateInvoiceButton(true);
    const formData = {
      products: [],
      services: [],
      memberships: [],
      packages: [],
      taxes: taxes,
      coupon_code: couponCode,
      additonal_discount: additionalDiscount,
      // amount_payable: calculatedSubTotal,
      amount_payable: parseInt(payableAmount),
      subTotal :  calculatedSubTotal,
      amount_paid: calculateTotalAmount(),
      payment_mode: paymentEntries,
      // amount_due: calculateRemainingAmount(),
      amount_due:  Number(calculateRemainingAmount()) > 0 ? Number(calculateRemainingAmount()) : 0,
      reward_points: giveRewardPoints ? countRewardPoints() : 0,
      client_name: selectedClient.name,
      client_number: parseInt(selectedClient.phone),
      billingDate: moment(billingDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      billingTime: billingTime,
    };

    // Function to calculate Staff Commission
    function calculateStaffCommission(total, staff, category) {
      if (category === "services") {
        return Math.round(
          (total * parseFloat(staff?.service_level_commission || 0)) / 100
        );
      } else if (category === "products") {
        return Math.round(
          (total * parseFloat(staff?.product_level_commission || 0)) / 100
        );
      } else {
        return 0;
      }
    }

    // Create a mapping of categories to their respective arrays
    const categoryMap = {
      Products: "products",
      Services: "services",
      Memberships: "memberships",
      Packages: "packages",
    };

    // Iterate through selected items to categorize them
    selectedItems.forEach((item) => {
      const categoryKey = categoryMap[item.category];
      if (categoryKey) {
        if (categoryKey === "packages") {
          // Process each sub-service within the package
          const subServices = item.sub_service_info.map((subService) => {
            const totalStylists = subService.stylist?.length || 0;
            const totalPerStylist = totalStylists
              ? subService.amount / totalStylists
              : 0;
            const staffCommission =
              subService.stylist?.map((staff) => ({
                staffID: staff.id,
                commission: calculateStaffCommission(
                  totalPerStylist,
                  staff,
                  "services"
                ),
              })) || [];
            return {
              ...subService,
              staff:
                subService.stylist?.map((staff) => ({
                  staffID: staff.id,
                  staffName: staff.staff_name,
                })) || [],
              staffCommission: staffCommission,
            };
          });
          formData[categoryKey].push({
            ...item,
            sub_service_info: subServices,
          });
        } else {
          const staffData =
            item.stylist?.map((staff) => ({
              staffID: staff.id,
              staffName: staff.staff_name,
            })) || [];
          const totalStylists = item.stylist?.length || 0;
          const totalPerStylist = totalStylists
            ? item?.total / totalStylists
            : 0;
          const staffCommission =
            item.stylist?.map((staff) => ({
              staffID: staff.id,
              commission: calculateStaffCommission(
                totalPerStylist,
                staff,
                categoryKey
              ),
            })) || [];
          formData[categoryKey].push({
            ...item,
            staff: staffData,
            staffCommission: staffCommission,
          });
        }
      } else {
        // Handle other categories or leave as is
      }
    });

    // console.log(formData);

    // dispatch(addRecordSale(formData));
    dispatch(updateRecordSale({ data: formData, id })).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        navigate("/sales/entries");
      } else {
        console.error("Error updating record sale:", action.payload);
      }
    });
  };

  const setAllCheckoutDetailes = (responseData) => {
    let items = [];
    // const productData = responseData?.data?.checkoutDetails?.products?.map(
    //   (item) => {
    //     return {
    //       ...item,
    //       name: item?.product_details?.name,
    //       price: item?.product_details?.price,
    //       quantity: item?.product_details?.quantity,
    //       discount: item?.product_details?.discount,
    //       service_details: { staff: item?.product_details?.staff },
    //       category: "Products",
    //     };
    //   }
    // );
    const productData = responseData?.data?.checkoutDetails?.products?.map(
      (item) => {
        return {
          ...item,
          name: item?.name,
          price: item?.price,
          quantity: item?.quantity,
          discount: item?.discount,
          // service_details: { staff: item?.product_details?.staff },
          category: "Products",
        };
      }
    );
    const serviceData = responseData?.data?.checkoutDetails?.services?.map(
      (item) => {
        return {
          ...item,
          name: item?.service_subService_name,
          price: item?.price,
          discount: item?.discount,
          quantity: item?.quantity,
        };
      }
    );
    const membershipData =
      responseData?.data?.checkoutDetails?.memberships?.map((item) => {
        return {
          ...item,
          name: item?.service_details?.name,
          price: item?.service_details?.price,
          quantity: item?.service_details?.quantity,
          discount: item?.service_details?.discount,
          memberships_category: item?.category,
          category: "Memberships",
        };
      });
    const packageData = responseData?.data?.checkoutDetails?.packages?.map(
      (item) => {
        const subServices = item?.sub_service_info.map((subItem) => ({
          ...subItem,
        }));
        return {
          ...item,
          name: item?.name,
          price: item?.price,
          quantity: item?.quantity,
          discount: item?.discount,
          isPackage: true,
          category: "Packages",
          sub_service_info: subServices,
        };
      }
    );
    items = [...productData, ...serviceData, ...membershipData, ...packageData];
    setSelectedItems(items);
    // ///////////////

    setClientSearchInput(responseData?.data?.checkoutDetails?.client_number);
    setSelectedClient(responseData?.data?.clientData);
    // setBillToListData(responseData?.data?.clientData);
    const selectDate = moment(
      responseData?.data?.checkoutDetails?.billingDate
    ).format("DD-MM-YYYY");
    setBillingDate(selectDate);
    setBillingTime(responseData?.data?.checkoutDetails?.billingTime);
    const tax_value =
      responseData?.data?.checkoutDetails?.taxes === 0 ||
      responseData?.data?.checkoutDetails?.taxes === "0"
        ? "0"
        : "products services memberships packages";
    setSelectedTax(tax_value);
    setAdditionalDiscount(
      responseData?.data?.checkoutDetails?.additonal_discount
    );

    setCouponCode(responseData?.data?.checkoutDetails?.coupon_code);
    const rewardPointValue =
      Number(responseData?.data?.checkoutDetails?.reward_points) === 0
        ? false
        : true;
    setPaymentEntries(responseData?.data?.checkoutDetails?.payment_mode);

    setGiveRewardPoints(rewardPointValue);
  };
  useEffect(() => {
    if (!id) {
      navigate("/sales/entries");
    } else {
      dispatch(fetchCheckoutDetails(id)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          // setCheckoutDetails(action.payload);
          // setFormData(action.payload); // Assuming payload has the form structure you need
          setAllCheckoutDetailes(action?.payload); // Assuming payload has the form structure you need
          setLoading(false);
        } else {
          setLoading(false);
          console.error("Error fetching record data:", action.payload);
        }
      });
    }
  }, [id, dispatch, navigate]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateRecordSale({ data: formData, id })).then((action) => {
      if (action.meta.requestStatus === "fulfilled") {
        navigate("/sales/entries");
      } else {
        console.error("Error updating record sale:", action.payload);
      }
    });
  };

  if (loading) {
    return (
      <>
        <PageLoader />
        <CommonLayout>
          <Row></Row>
        </CommonLayout>
      </>
    );
  }

  if (!checkoutDetails) {
    return (
      <CommonLayout>
        <Row>
          <Alert variant="danger" onClick={() => navigate("/sales/entries")}>
            Please select an invoice to edit.
          </Alert>
        </Row>
      </CommonLayout>
    );
  }
  return (
    <div>
      <CommonLayout>
        <Row>
          <Col className="min-w-0">
            <h4 className="mb-2">Edit sale</h4>
            <p className="mb-7">Edit your sales here and generate invoices</p>
            {/* <h4 className="mb-2">Record new sale</h4>
            <p className="mb-7">
              Add your daily sales here and generate invoices
            </p> */}
            <p className="mb-5">Bill to</p>
            <Row className="mb-8 gy-3">
              <Col lg={4}>
                <p className="text-xs mb-2">Client number*</p>

                <Dropdown className="bg-light dropdown-toggle-remove w-full">
                  <Dropdown.Toggle
                    id="dropdown-radio"
                    className="bg-light border-0 p-0 w-full"
                  >
                    <InputGroup className="form-group-1 border me-auto w-full">
                      <FormControl
                        className="border-0"
                        type="text"
                        name="search"
                        value={clientSearchInput}
                        onChange={handleClientSearchInputChange}
                        placeholder="Search by number/name..."
                        autoComplete="off"
                        disabled
                      />
                    </InputGroup>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-full">
                    {clientSearchResults?.map((result) => (
                      <Dropdown.Item
                        key={result._id}
                        onClick={() => {
                          setSelectedClient(result);
                          setClientSearchInput(result?.phone);
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <p className=" text-primary">{` ${result.name} `}</p>
                          <p className=" text-primary">{` ${result.phone}`}</p>
                        </div>
                      </Dropdown.Item>
                    ))}
                    {loading && (
                      <Dropdown.ItemText>Loading...</Dropdown.ItemText>
                    )}
                    {clientSearchResults?.length === 0 && !loading && (
                      <Dropdown.ItemText>
                        <div className="d-flex align-items-center justify-content-between">
                          {`${
                            clientSearchInput !== ""
                              ? "No Client found "
                              : "Add new Client "
                          }`}
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setSmShow(true);
                              // navigate("/client/addclient");
                            }}
                          >
                            Add client
                          </Button>
                        </div>
                      </Dropdown.ItemText>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <p className="text-danger">{error?.selectedClient}</p>
              </Col>
              <Col lg={4}>
                <p className="text-xs mb-2">Billing date</p>
                <form>
                  <InputGroup>
                    <DateTime
                      value={billingDate}
                      input={true} // Enable the input field
                      dateFormat="DD-MM-YYYY" // Date format
                      timeFormat={false} // Disable the time picker
                      onChange={(time) =>
                        handleDateChange("billingDate", time, true)
                      }
                      inputProps={{ placeholder: "DD-MM-YYYY" }}
                    />
                  </InputGroup>
                </form>
                <p className="text-danger">{error?.billingDate}</p>
              </Col>
              <Col lg={4}>
                <p className="text-xs mb-2">Billing time</p>
                <InputGroup>
                  <DateTime
                    value={billingTime}
                    onChange={(time) =>
                      handleDateChange("billingTime", time, false)
                    }
                    inputProps={{ placeholder: "--:-- --" }}
                    input={true} // Disable the input field
                    dateFormat={false} // Disable the date picker
                    timeFormat="hh:mm A" // 12-hour format with AM/PM
                  />
                </InputGroup>
                <p className="text-danger">{error?.billingTime}</p>
              </Col>
            </Row>
            <div className="d-none">
              <p className="mb-7">Add items</p>
              <Row>
                <Col lg={12}>
                  <div style={{ maxWidth: "440px" }}>
                    <Stack
                      className="flex-wrap mb-2 justify-content-between"
                      // className=" mb-2 justify-content-between"
                      direction="horizontal"
                      gap={3}
                    >
                      {buttonData.map((buttonName) => (
                        <Button
                          key={buttonName}
                          variant=""
                          size="sm"
                          className={`fillter-button-primary px-2 py-1 ${
                            activeButton === buttonName ? "active" : ""
                          }`}
                          onClick={() => handleButtonClick(buttonName)}
                          disabled
                        >
                          {buttonName}
                        </Button>
                      ))}
                    </Stack>
                  </div>
                </Col>
              </Row>
              <Row className="pb-4 shadow mb-32">
                <Col lg={12}>
                  <div style={{ maxWidth: "440px" }}>
                    <Dropdown
                      className="bg-light dropdown-toggle-remove w-full"
                      autoClose="outside"
                    >
                      <Dropdown.Toggle
                        id="dropdown-autoclose-outside"
                        className="bg-light border-0 p-0 w-full"
                      >
                        <InputGroup className="form-group-1 border me-auto w-full">
                          <InputGroup.Text className="border-0  bg-transparent pe-1 ps-3 disabled">
                            <BiSearch className="text-lg" />
                          </InputGroup.Text>

                          <FormControl
                            className="border-0 "
                            type="text"
                            name="search"
                            value={searchInput}
                            onChange={handleSearchInputChange}
                            placeholder="Search by name"
                            // disabled={!selectedClient}
                            disabled
                          />
                        </InputGroup>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-full">
                        {activeButton === "Services"
                          ? searchResults?.map((result) => (
                              <Dropdown.Item key={result.id}>
                                <Form.Check
                                  type="checkbox"
                                  id={`service_${result.id}`}
                                >
                                  <Form.Check.Input
                                    type="checkbox"
                                    checked={isSelected(
                                      result.id,
                                      activeButton
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(result, activeButton)
                                    }
                                    // checked={selectedServices.includes(result.id)}
                                    // onChange={() => handleCheckboxChange(result)}
                                  />
                                  <Form.Check.Label className="ms-2 text-wrap">
                                    {result?.service_subService_name}
                                  </Form.Check.Label>
                                </Form.Check>
                              </Dropdown.Item>
                            ))
                          : searchResults?.map((result) => (
                              <Dropdown.Item key={result.id}>
                                <Form.Check
                                  type="checkbox"
                                  id={`service_${result.id}`}
                                >
                                  <Form.Check.Input
                                    type="checkbox"
                                    checked={isSelected(
                                      result.id,
                                      activeButton
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(result, activeButton)
                                    }
                                    // checked={selectedServices.includes(result.id)}
                                    // onChange={() => handleCheckboxChange(result)}
                                  />
                                  <Form.Check.Label className="ms-2 text-wrap">
                                    {result?.name}
                                    {activeButton === "Memberships" &&
                                      ` (${result?.category || ""})`}
                                    {activeButton === "Packages" &&
                                      ` (${
                                        ShowTotalEntriesCount(
                                          result?.sub_service_info?.length,
                                          "Service",
                                          "Services"
                                        ) || ""
                                      })`}
                                  </Form.Check.Label>
                                </Form.Check>
                              </Dropdown.Item>
                            ))}

                        {/* <Dropdown.Divider /> */}
                        {loading && (
                          <Dropdown.ItemText>Loading...</Dropdown.ItemText>
                        )}
                        {searchResults?.length === 0 && !loading && (
                          <Dropdown.ItemText>No result found</Dropdown.ItemText>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
                <p className="text-danger">
                  {selectedClient
                    ? ""
                    : "Please select client first to add any item"}
                </p>
              </Row>
            </div>
            {/* Add items section end */}
            {/* items section start */}
            <p className="mb-4">Items</p>
            <div className=" mb-12">
              {/* <div className="table-responsive mb-12">  */}
              <Table borderless hover responsive>
                <thead>
                  <tr className="shadow">
                    <th className="text-sm text-dark">Stylist</th>
                    <th className="text-sm text-dark">Item</th>
                    <th className="text-sm text-dark">Qty</th>
                    <th className="text-sm text-dark">Price</th>
                    <th className="text-sm text-dark">Discount</th>
                    <th className="text-sm text-dark">Total</th>
                  </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
                <p className="text-danger">{error?.selectedItem}</p>
              </Table>
              <p className="text-danger">{error?.stylist}</p>
              <p className="text-danger">{error?.discount_value}</p>

              {/* <div>Total Price: Rs{calculateTotalPrice()}</div> */}
            </div>
            <Form>
              <Row className="mb-10 gy-6">
                <Col xxl={4} xl={6}>
                  <FormGroup className="hstack gap-3">
                    <p className="text-sm">Taxes</p>
                    <Form.Select
                      className="form-select-sm"
                      value={selectedTax}
                      onChange={handleTaxChange}
                      defaultValue={"Choose..."}
                    >
                      <option disabled>Choose...</option>
                      {taxOptionsLables.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </FormGroup>
                  <p className="text-danger">{error?.taxes}</p>
                </Col>
                <Col xxl={4} xl={6}>
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    placeholder="Enter coupon code"
                    value={couponCode}
                    onChange={handleCouponCodeChange}
                    onBlur={checkCouponDiscount}
                    disabled
                  />
                  <p className="text-success ms-3">
                    {couponDiscount?.offerText}
                  </p>
                </Col>
                <Col xxl={4}>
                  <FormGroup className="hstack gap-3" aria-disabled>
                    <p className="text-nowrap text-sm">Additional discount</p>
                    <InputGroup size="sm">
                      <Form.Control
                        aria-label="Text input with dropdown button"
                        className="border-end-0 w-1/2 form-control-sm"
                        value={additionalDiscount.value}
                        onChange={(e) =>
                          handleAdditionalDiscountChange(
                            "value",
                            e.target.value
                          )
                        }
                        disabled
                      />

                      <Form.Select
                        aria-label="Default select example"
                        className="pe-7 ps-0 text-end border-start-0"
                        value={additionalDiscount.type}
                        onChange={(e) =>
                          handleAdditionalDiscountChange("type", e.target.value)
                        }
                        disabled
                      >
                        <option value="%">%</option>
                        <option value="Rs">Rs</option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                  <p className="text-danger">{error?.additionalDiscount}</p>
                </Col>
              </Row>
            </Form>
            {/* items section end */}
          </Col>
          <Col className="width-360 card-lotion-bg col-auto mt-n10">
            <div className="vstack h-100">
              <div className="bg-gray-light-200 p-4 mt-4 mb-10">
                <Stack direction="horizontal" gap={4} className="flex-wrap">
                  <p className="text-sm">
                    Name:
                    <span className="text-dark font-semibold ms-2">{`${
                      selectedClient?.name ? selectedClient?.name : "--"
                    }`}</span>
                  </p>
                  <p className="text-sm">
                    Status:
                    <span className="text-dark font-semibold ms-2">{`${
                      selectedClient?.tag ? selectedClient?.tag : "--"
                    }`}</span>
                  </p>
                  <p className="text-sm">
                    Membership:
                    <span className="text-dark font-semibold ms-2">{`${
                      selectedClient?.membership?.name
                        ? selectedClient?.membership?.name
                        : "--"
                    }`}</span>
                  </p>
                  <p className="text-sm">
                    Reward points:
                    <span className="text-dark font-semibold ms-2">{`${
                      selectedClient?.reward_points
                        ? selectedClient?.reward_points
                        : "--"
                    }`}</span>
                  </p>
                  <p className="text-sm">
                    Balance:
                    <span className="text-dark font-semibold ms-2">{`${
                      selectedClient?.balance ? selectedClient?.balance : "--"
                    }`}</span>
                  </p>
                  {selectedClient?.membership !== null && (
                    <p className="text-sm">
                      Membership Balance:
                      <span className="text-dark font-semibold ms-2">{`${
                        selectedClient?.service_balance
                          ? selectedClient?.service_balance
                          : "0"
                      }`}</span>
                    </p>
                  )}
                   {/* {selectedClient?.amount_due !== null && ( */}
                    <p className="text-sm">
                      Amount Due:
                      <span className="text-dark font-semibold ms-2">{`${
                        selectedClient?.amount_due
                          ? selectedClient?.amount_due
                          : "0"
                      }`}</span>
                    </p>
                  {/* )} */}
                  <p className="text-sm">
                    Total visits:
                    <span className="text-dark font-semibold ms-2">{`${
                      selectedClient?.total_visits
                        ? selectedClient?.total_visits
                        : "--"
                    }`}</span>
                  </p>
                  <p className="text-sm">
                    Last visited:
                    <span className="text-dark font-semibold ms-2">
                      {/* {`${
                      selectedClient?.last_visited
                        ? moment(selectedClient?.last_visited).format(
                            "DD MMM YYYY"
                          )
                        : "--"
                    }`} */}
                      {`${
                        selectedClient?.last_visited
                          ? selectedClient?.last_visited
                          : "--"
                      }`}
                    </span>
                  </p>
                </Stack>
              </div>
              <div
                className={`${selectedItems.length > 0 ? "d-block" : "d-none"}`}
              >
                <InvoiceSummary
                  selectedItems={selectedItems} // Array of selected items with category, name, price, total, etc.
                  couponDiscount={couponDiscount} // Coupon discount value (can be a number or percentage)
                  additionalDiscount={additionalDiscount} // Additional discount value (can be a number or percentage)
                  taxRate={18} // Tax rate (percentage)
                  taxOption={selectedTax} // Tax option string (e.g., "18% on products and services")
                  setPayableAmount={setPayableAmount}
                  setTaxes={setTaxes}
                />

                {/* Below Div is part of one payment mode */}
                <div className="mb-12">
                  <p className="text-sm text-dark font-semibold my-4">
                    Amount paid
                  </p>
                  <AmountInputForm
                    selectedClient={selectedClient}
                    paymentEntries={paymentEntries}
                    setPaymentEntries={setPaymentEntries}
                    payableAmount={payableAmount}
                    error={error}
                    setError={setError}
                  />
                </div>

                <div className="mt-auto">
                  {giveRewardPoints && (
                    <Alert
                      key="success"
                      variant="success"
                      className="py-2 px-4 text-xs rounded-0 border-0"
                    >
                      {selectedClient?.name
                        ? `${selectedClient?.name} `
                        : "Customer "}
                      is getting {countRewardPoints()} loyalty points on this
                      bill
                    </Alert>
                  )}
                  <Stack
                    direction="horizontal"
                    className="justify-content-between mb-8"
                  >
                    <div>
                      <p className="mb-1 text-dark text-sm">
                        Give reward points{" "}
                      </p>
                      <p className="text-xs">
                        *Loyalty points:- 1 points = 1.00 INR.
                      </p>
                    </div>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      checked={giveRewardPoints}
                      onChange={handleGiveRewardPointsChange}
                    />
                  </Stack>
                  <Button
                    variant="secondary"
                    className="w-full mb-2"
                    // onClick={() => setLgShow(true)}
                    disabled={disableGenerateInvoiceButton}
                    onClick={handleGenerateinvoice}
                  >
                    Generate invoice
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="w-full mb-2"
                    // onClick={() => setLgShow(true)}
                    disabled={disableGenerateInvoiceButton}
                    onClick={() => navigate("/sales/entries")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <RecordInventoryModel
          show={lgShow}
          setShow={setLgShow}
          checkOutId={checkOutId}
          pdfUrl={pdfUrl}
        />
        {smShow && <AddClientModel setSmShow={setSmShow} smShow={smShow} />}
      </CommonLayout>
    </div>
  );
};

export default EditSale;
